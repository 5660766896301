import { CmsWidgetControlProps } from 'decap-cms-core'
import React, { FC, useRef } from 'react'

export const Guid: FC<CmsWidgetControlProps<string>> = ({
  value,
  classNameWrapper,
  forID,
  onChange
}) => {
  const guid = useRef('')

  const { href, origin } = location
  const isNewPage = href.replace(origin, '').split(/\/{1,}/)[4] === 'new'

  const noValueExists = !value
  const idHasBeenSet = !!guid.current

  // new guid needs to be created
  if (!idHasBeenSet && (noValueExists || isNewPage)) {
    guid.current = createNewId()
    onChange(guid.current)
  }

  // value already exists but has not been assigned to guid ref
  if (!idHasBeenSet && !(noValueExists || isNewPage)) {
    guid.current = value
  }

  // fallback if value jumps back to old value when duplicating document
  if (guid.current !== value) {
    onChange(guid.current)
  }

  return (
    <div>
      {guid.current}
      <input
        type="hidden"
        value={guid.current}
        id={forID}
        className={classNameWrapper}
      />
    </div>
  )
}

const createNewId = () => Math.random().toString(36).substring(2, 11)
