function getYoutubeVideoIdFromURL(url: string) {
  // Handle null or empty input
  if (!url) return null

  try {
    // Convert URL string to URL object
    const urlObj = new URL(url)

    // Case 1: youtu.be links
    if (urlObj.hostname === 'youtu.be') {
      const videoId = urlObj.pathname.slice(1)
      return videoId ? videoId.replace(/\/$/, '') : null
    }

    // Case 2 & 3: youtube-nocookie.com/embed and youtube.com/embed
    if (
      (urlObj.hostname === 'www.youtube-nocookie.com' ||
        urlObj.hostname === 'www.youtube.com') &&
      urlObj.pathname.includes('/embed/')
    ) {
      const embedId = urlObj.pathname.split('/embed/')[1]?.split('?')[0]
      return embedId ? embedId.replace(/\/$/, '') : null
    }

    // Case 4: youtube.com/watch with v parameter
    if (urlObj.hostname === 'www.youtube.com' && urlObj.searchParams.has('v')) {
      const videoId = urlObj.searchParams.get('v')
      return videoId ? videoId.replace(/\/$/, '') : null
    }

    return null
  } catch (error) {
    // Return null if URL is invalid
    return null
  }
}

export default getYoutubeVideoIdFromURL
