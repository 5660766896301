import classNames from 'classnames'
import { FC } from 'react'
import { TwoColumnsExtendedComponentContent } from '../../types/page_component_types'
import { Section, MarkdownComponent } from '..'
import { ButtonComponent } from './ButtonComponent'
import { TwoColumnsComponent } from './TwoColumnsComponent'

export const TwoColumnsExtendedComponent: FC<{
  content: TwoColumnsExtendedComponentContent
  isPreview: boolean
}> = ({ content, isPreview }) => {
  const hasRichText = !!content.richText
  const hasButton =
    content.linkbutton && content.linkbutton?.label && content.linkbutton?.link

  let sectionClassName: string | undefined
  let darkBackground = false
  let fontColor: string | undefined

  if (content.background === 'gradient') {
    sectionClassName = 'bg-gradient-to-r from-[#FFEFD2] to-[#FFD9D9]'
  } else if (content.background === 'light_blue') {
    sectionClassName = 'bg-blue10'
  } else if (content.background === 'blue') {
    darkBackground = true
    fontColor = 'text-white'
  }

  return (
    <Section
      sectionClassName={sectionClassName}
      darkBackground={darkBackground}
    >
      {hasRichText && (
        <MarkdownComponent
          markdown={content.richText as string}
          className={classNames('mt-5 md:mt-10 text-center', fontColor)}
        />
      )}

      <TwoColumnsComponent
        content={{ ...content.twocolumns, type: 'twocolumns' }}
        isPreview={isPreview}
        darkBackground={darkBackground}
      />

      {hasButton && (
        <ButtonComponent
          content={{ ...content.linkbutton, type: 'linkbutton' }}
        />
      )}
    </Section>
  )
}
