import React, { FC } from 'react'
import { PortfolioComponentContent } from '../../types/page_component_types'
import Image from 'next/legacy/image'
import { Slider, Section, ButtonContent, MarkdownComponent } from '../'
import { Image as TImage } from '../../types/page_component_types'

import classNames from 'classnames'
import Link from 'next/link'
import { useTranslations } from 'next-intl'

interface PortfolioItemProps {
  header: string
  text: string
  image?: TImage
  blueBg?: boolean
  button?: boolean
  slug: string
}

export const Portfolio: FC<PortfolioItemProps> = ({
  header,
  text,
  image,
  blueBg,
  button,
  slug
}) => {
  const t = useTranslations()

  const CardContent = () => (
    <div
      className={classNames(
        'group h-[35rem] w-full sm:h-[45rem] md:grid md:h-[37.5rem] md:grid-flow-col md:grid-cols-3 ',
        blueBg ? 'yellowBlue' : 'yellowPink'
      )}
    >
      <figure className="relative h-1/2 md:col-span-2 md:h-[37.5rem]">
        {image?.src && (
          <Image
            className="clipPortfolioMobile md:clipPortfolio"
            src={image.src}
            alt={image.alt || ''}
            layout="fill"
            objectFit="cover"
          />
        )}
      </figure>
      <article className="flex h-1/2 select-text flex-col p-4 md:col-span-1 md:h-full md:p-10">
        <h3
          className={classNames(
            'mt-4 md:mt-20',
            button && 'group-hover:text-blue70'
          )}
        >
          {header}
        </h3>
        <MarkdownComponent
          className="mt-2 flex-1 line-clamp-8 md:mt-8"
          markdown={text || ''}
        />
        {button && <ButtonContent label={t('read_more')} />}
      </article>
    </div>
  )

  if (button) {
    return (
      <Link href={slug || ''}>
        <CardContent />
      </Link>
    )
  }

  return <CardContent />
}

/* https://www.figma.com/file/dlNJahOgzHG8DrFEL3srnt/Design-System?node-id=231%3A1702 */
export const PortfolioComponent: FC<{ content: PortfolioComponentContent }> = ({
  content
}) => {
  return (
    <Section>
      <Slider>
        {content?.cards?.map((card, i) => (
          <Portfolio
            key={i}
            header={card.heading || ''}
            text={card.description || ''}
            blueBg={!(card.background === 'redBackground')}
            image={card.image}
            button={card.read_more_button_is_displayed}
            slug={card.slug}
          />
        ))}
      </Slider>
    </Section>
  )
}
