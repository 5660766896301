import React, { FC } from 'react'
import { WhenwhereComponentContent } from '../../types/page_component_types'
import { Section, MarkdownComponent } from '../'
import whenWhereIcon from '../../assets/orangeshapes.png'
import Image from 'next/legacy/image'
import { FormatDateTimePeriod } from '../Time'
import { useTranslations } from 'next-intl'

/* use existing component from nc2019 */
export const WhenwhereComponent: FC<{
  content: WhenwhereComponentContent
}> = ({ content }) => {
  const t = useTranslations()
  
  const time = content?.eventItem?.time
  const location = content?.eventItem?.location

  if (!time || !location?.address) return null
  const startDate = time?.starttime && new Date(time.starttime)
  const endDate = time?.endtime && new Date(time.endtime)

  const icon = (
    <div className="mb-5 inline-block w-20">
      <Image
        src={whenWhereIcon}
        alt="when and where icon"
        layout="responsive"
        className="w-full"
        objectFit="contain"
      />
    </div>
  )

  const addressRepresentation = location?.googlelink ? (
    <a href={location.googlelink} target="_blank" rel="noreferrer">
      <MarkdownComponent
        className="text-sm font-normal normal-case underline hover:text-turquoise70"
        markdown={location?.address || ''}
      />
    </a>
  ) : (
    <MarkdownComponent markdown={location?.address || ''} />
  )

  const when = startDate && endDate && (
    <>
      {icon}
      <h5 className="mb-5">{t('when')}</h5>
      <div className="mx-auto block sm:w-7/10">
        <FormatDateTimePeriod startDate={startDate} endDate={endDate} />
      </div>
    </>
  )

  const where = (location?.googlelink || location?.address) && (
    <>
      {icon}
      <h5 className="mb-5">{t('where')}</h5>
      <div className="mx-auto block sm:w-7/10">{addressRepresentation}</div>
    </>
  )

  return (
    <Section>
      <div className="mx-auto flex max-w-[980px] flex-col text-center text-sm font-light sm:flex-row">
        <div className="mb-10 sm:mb-0 sm:w-1/2">{when}</div>
        <div className="sm:w-1/2">{where}</div>
      </div>
    </Section>
  )
}
