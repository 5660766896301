import Link from 'next/link'
import { FC } from 'react'
import { ButtonComponentContent } from '../../types/page_component_types'
import { ButtonPrimary, ButtonSecondary } from '../Buttons'
import { Section } from '../Section'
import { isExternalLink } from '../../utils/isExternalLink'

/* https://www.figma.com/file/dlNJahOgzHG8DrFEL3srnt/Design-System?node-id=8%3A78 */
export const ButtonComponent: FC<{ content: ButtonComponentContent }> = ({
  content
}) => {
  const isPrimaryButton =
    !content.button_type || content.button_type === 'primary'

  const Button: FC<{
    label: string
    link: string
    [restProps: string]: any
  }> = ({ ...props }) => {
    if (isPrimaryButton) {
      return <ButtonPrimary {...props} />
    }
    return <ButtonSecondary {...props} />
  }

  return (
    <div className="mb-5 mt-3.5 flex justify-center md:mt-6 md:mb-10">
      <Button
        link={content.link || ''}
        label={content.label || ''}
        target={isExternalLink(content.link || '') ? '_blank' : '_self'}
        rel={isExternalLink(content?.link || '') ? `noreferrer` : ''}
      />
    </div>
  )
}
