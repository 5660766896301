import React, { FC } from 'react'
import { ButtonTertiary } from './Buttons'
import Image from 'next/legacy/image'
import Link from 'next/link'
import { useRouter } from 'next/compat/router'
import { Locale } from '../types/shared_types'

export interface BlogCard {
  title: string
  author: string
  publicationDate: string
  excerpt: string
  alt: string
  src: string
  slug: string
}

const dayOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric'
}

export const BlogCard: FC<
  Pick<
    Required<BlogCard>,
    'title' | 'author' | 'publicationDate' | 'excerpt' | 'alt' | 'src' | 'slug'
  >
> = ({ src, alt, title, author, publicationDate, excerpt, slug }) => {
  const locale = (useRouter()?.locale || 'en') as Locale

  return (
    <Link href={slug || ''}>
      <article className="group flex w-[360px] flex-col overflow-hidden md:h-[48rem]  md:pb-4">
        <Image src={src} alt={alt || ''} width={360} height={240} />
        <p className=" py-4 text-blue70 ">
          {new Date(publicationDate).toLocaleDateString(locale, dayOptions)}
        </p>

        <h4 className="group-hover:text-turquoise70">{title}</h4>
        <p className="py-4 text-blue70">{author}</p>
        <p>{excerpt}</p>
      </article>
    </Link>
  )
}
