import React, { CSSProperties, FC, useContext, useRef, useState } from 'react'
import classNames from 'classnames'
import { AppContext } from '../../utils/context'
import { VideoComponentContent } from '../../types/page_component_types'
import { Section } from '../'

import ReactPlayer from 'react-player/lazy'
import { useRouter } from 'next/router'
import { Locale } from '../../types/shared_types'
import getThumbnailFromURL from '../../utils/video/get_thumbnail_from_url'
import updateVideoOptIn from '../../utils/cookie/update_video_optin'
import { useTranslations } from 'next-intl'

const sizeClasses = {
  small: 'sm:w-3/5',
  medium: 'sm:w-4/5',
  large: undefined
}

/* https://www.figma.com/file/dlNJahOgzHG8DrFEL3srnt/Design-System?node-id=413%3A5499 */
export const VideoComponent: FC<{
  content: VideoComponentContent
  isPreview: boolean
  noSection?: boolean
  darkBackground?: boolean
}> = ({ content, isPreview, noSection = false, darkBackground = false }) => {
  const cookieConsent = useContext(AppContext)
  const [isPlaying, setIsPlaying] = useState(false)

  if (!content?.video) return null

  let videoSizeClassName: string | undefined
  if (content.size) {
    videoSizeClassName = sizeClasses[content.size]
  }

  const videoClassName = classNames(
    'relative w-full aspect-video',
    { 'mx-auto': !!content.isCentered },
    videoSizeClassName
  )

  // Either general cookie does not exist, or the experience cookie set to false
  // Show the thumbnail and ask for consent
  if ((!cookieConsent || !cookieConsent.consentData?.[3]) && !isPreview) {
    return (
      <Wrapper noSection={noSection}>
        <ThumbnailWithCookie
          video={content.video}
          isCentered={!!content.isCentered}
          className={videoClassName}
          setIsPlaying={setIsPlaying}
        />
      </Wrapper>
    )
  }

  return (
    <Wrapper noSection={noSection}>
      <VideoElement
        video={content.video}
        className={videoClassName}
        isPlaying={isPlaying}
      />
    </Wrapper>
  )
}

const Wrapper: FC<{
  noSection: boolean
  children: React.ReactNode
}> = ({ noSection, children }) => {
  if (noSection) {
    return <>{children}</>
  }

  return <Section>{children}</Section>
}

const VideoElement: FC<{
  video: string
  className: string
  isPlaying: boolean
}> = ({ video, className, isPlaying }) => {
  return (
    <div className={className}>
      <ReactPlayer
        className="absolute top-0 left-0"
        url={video}
        controls={true}
        width="100%"
        playing={isPlaying}
        height="100%"
      />
    </div>
  )
}

const ThumbnailWithCookie: FC<{
  video: string
  isCentered: boolean
  className: string
  darkBackground?: boolean
  setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ video, isCentered, className, darkBackground, setIsPlaying }) => {
  const cookieConsent = useContext(AppContext)
  const router = useRouter()
  const t = useTranslations()

  const thumbnailImg = getThumbnailFromURL(video)

  let style: CSSProperties | undefined = undefined
  if (thumbnailImg) {
    style = { backgroundImage: `url(${thumbnailImg})` }
  }

  const locale = (router.locale || 'en') as Locale
  let iubendaId: string | undefined

  if (locale === 'de') {
    iubendaId = process.env.NEXT_PUBLIC_IUBENDA_POLICYID_DE
  } else {
    iubendaId = process.env.NEXT_PUBLIC_IUBENDA_POLICYID_EN
  }

  const handleClick = () => {
    updateVideoOptIn(cookieConsent, iubendaId as string)
    setIsPlaying(true)
  }

  return (
    <div>
      <div
        onClick={handleClick}
        style={style}
        className={classNames(
          className,
          'bg-contain bg-center flex justify-center items-center cursor-pointer',
          {
            'bg-white': darkBackground,
            'bg-blue100': !darkBackground
          }
        )}
      >
        <div className="flex items-center justify-center w-20 h-12 bg-red100 rounded-lg">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="white"
            viewBox="0 0 36 36"
            className="w-10 h-10"
          >
            <path d="M12 9v18l15-9z" />
          </svg>
        </div>
      </div>
      <p
        className={classNames('text-xs pb-2', {
          'text-center': isCentered
        })}
      >
        {t('video_consent_text')}
      </p>
    </div>
  )
}
