import classNames from 'classnames'
import Link from 'next/link'
import { FC } from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

const isInternalUrl = (href: string, origin: string): string | false => {
  let url: URL | null = null
  let hostUrl: URL | null = null
  try {
    url = new URL(href)
    hostUrl = new URL(origin)
  } catch (error) {
    return href
  }

  if (url.host === hostUrl.host) {
    return href.replace(url.origin, '')
  }

  return false
}

// https://amirardalan.com/blog/use-next-image-with-react-markdown
// https://dev.to/felixhaeberle/responsive-fix-for-the-next-js-image-component-1351
export const MarkdownComponent: FC<{
  className?: string
  markdown: string
}> = ({ className, markdown }) => {
  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      className={classNames('contentStyles', className)}
      components={{
        a: ({ href, children, ...props }) => {
          const externalLink = (
            <a href={href} {...props}>
              {children}
            </a>
          )

          if (!process.env.NEXT_PUBLIC_HOSTNAME || !href) {
            return externalLink
          }
          const path = isInternalUrl(href, process.env.NEXT_PUBLIC_HOSTNAME)

          if (path) {
            return <Link href={path}>{children}</Link>
          }

          return externalLink
        },
        table: ({ node, ...props }) => (
          <div className="overflow-x-auto">
            <table {...props} />
          </div>
        ),
      }}
    >
      {markdown}
    </ReactMarkdown>
  )
}
