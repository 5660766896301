import { FC } from 'react'
import { QuoteComponentContent } from '../../types/page_component_types'
import { TestimonialCard, Section } from '../'

/* not compatible with new CMS, ask Aga */
export const QuoteComponent: FC<{ content: QuoteComponentContent }> = ({
  content
}) => {
  return (
    <Section
      fullWidth
      className="clipBg bg-blue10 px-6 pt-10 pb-16 md:w-full md:py-20"
    >
      <TestimonialCard text={content.quote || ''} />
    </Section>
  )
}
