import React, { FC } from 'react'
import Image from 'next/legacy/image'
import Link from 'next/link'
import { Link as TLink } from '../types/shared_types'
import mapImg from '../assets/map-min.png'
import { isExternalLink } from '../utils/isExternalLink'

interface FooterContent {
  heading?: string
  address?: { heading?: string; address?: { address_line?: string }[] }
  email?: {
    heading?: string
    email?: string
  }
  follow_us?: {
    heading?: string
    socialChannels?: {
      icon?: string
      href?: string
    }[]
  }
  site?: {
    heading?: string
    links?: (TLink | undefined)[]
  }
  legal?: {
    heading?: string
    links?: (TLink | undefined)[]
  }
}

export const Footer: FC<{ items: FooterContent | null }> = ({ items }) => {
  return (
    <footer className="bg-blue100 py-10 px-6 text-white md:py-20">
      <div className="mx-auto w-[70.5rem] max-w-full">
        <h2 className="underline-headline-white">{items?.heading}</h2>

        <div className="flex flex-wrap gap-10 lg:flex-nowrap">
          <address className="not-italic md:min-w-[18.5rem]">
            <h5 className="mt-10 text-yellow50">{items?.address?.heading}</h5>
            <p className="mt-4">
              {items?.address?.address?.map((line, index) => {
                return (
                  <React.Fragment key={index}>
                    {line.address_line} <br />
                  </React.Fragment>
                )
              })}
            </p>

            <h5 className="mt-10 uppercase text-yellow50">
              {items?.email?.heading}
            </h5>
            <address className="mt-4 not-italic text-button">
              <a href={`mailto: ${items?.email?.email}`}>
                {items?.email?.email}
              </a>
            </address>
            <h5 className="mt-10 text-yellow50">{items?.follow_us?.heading}</h5>
            <ul className="mt-4 flex flex-wrap">
              {items?.follow_us?.socialChannels?.map((item, i) => {
                return (
                  <li key={i}>
                    <Link
                      href={item.href || ''}
                      target="_blank"
                      rel="noreferrer"
                      className="mr-6 h-14 w-14 md:mb-6"
                      aria-label={`Link to ${item.href}`}
                    >
                      <Image
                        alt=""
                        src={item?.icon || ''}
                        width="56"
                        height="56"
                      />
                    </Link>
                  </li>
                )
              })}
            </ul>
          </address>
          <section>
            <h5 className="text-yellow50 md:mt-10">{items?.site?.heading}</h5>
            <ul className="mt-4">
              {items?.site?.links?.map((link, i) => {
                return (
                  <li className="mt-6 text-button" key={i}>
                    <Link
                      href={link?.href || ''}
                      target={
                        isExternalLink(link?.href || '') ? '_blank' : '_self'
                      }
                      rel={isExternalLink(link?.href || '') ? `noreferrer` : ''}
                    >
                      {link?.label || ''}
                    </Link>
                  </li>
                )
              })}
            </ul>
            <h5 className="mt-10 text-yellow50">{items?.legal?.heading}</h5>
            <ul className="mt-4">
              {items?.legal?.links?.map((link, i) => {
                // Client-routing with <Link> does not fire the iubenda scripts
                // properly on the cookies and privacy pages
                if (link?.href === '/privacy') {
                  return (
                    <li className="mt-6 text-button" key={i}>
                      <a href={link?.href}>{link?.label || ''}</a>
                    </li>
                  )
                }
                if (link?.href === '/cookies') {
                  return (
                    <li className="mt-6 text-button" key={i}>
                      <a href="#" className="iubenda-cs-preferences-link">
                        {link?.label}
                      </a>
                    </li>
                  )
                }
                return (
                  <li className="mt-6 text-button" key={i}>
                    <Link
                      href={link?.href || ''}
                      target={
                        isExternalLink(link?.href || '') ? '_blank' : '_self'
                      }
                      rel={isExternalLink(link?.href || '') ? `noreferrer` : ''}
                    >
                      {link?.label || ''}
                    </Link>
                  </li>
                )
              })}
            </ul>
          </section>
          <Map />
        </div>
      </div>
    </footer>
  )
}

const Map = () => (
  <aside className="relative m-auto min-h-[20rem] min-w-[20rem] max-w-[41.25rem] lg:max-w-[28rem]">
    <a
      target="_blank"
      href="https://g.page/spielfelddigitalhub"
      rel="noreferrer"
      className="flex h-full w-full"
    >
      <Image
        src={mapImg}
        objectFit="contain"
        objectPosition="top"
        alt="N3xtcoder location"
        width={453}
        height={504}
      />
    </a>
  </aside>
)
