import classNames from 'classnames'
import { FC } from 'react'
import { ThreeColumnsComponentContent } from '../../types/page_component_types'
import { Section, MarkdownComponent } from '../'
import { VideoComponent } from './VideoComponent'

/*
 * https://www.figma.com/file/dlNJahOgzHG8DrFEL3srnt/Design-System?node-id=274%3A4863
 * https://www.figma.com/file/dlNJahOgzHG8DrFEL3srnt/Design-System?node-id=274%3A5037
 * to make ThreeColumnsComponent compatible with old CMS: out of markdown created jsx/html needs to be styled (f.e. images above columns' headings as defined in figma)
 */
export const ThreeColumnsComponent: FC<{
  content: ThreeColumnsComponentContent
  isPreview: boolean
  darkBackground?: boolean
}> = ({ content, isPreview, darkBackground = false }) => {
  return (
    <Section darkBackground={darkBackground}>
      <article className="grid-cols-3 gap-6 md:grid">
        <div className="col-1 mb-8">
          {content.col1Video && (
            <div className="mb-8">
              <VideoComponent
                content={{
                  type: 'video',
                  video: content.col1Video
                }}
                isPreview={isPreview}
                noSection
              />
            </div>
          )}

          <MarkdownComponent
            className={classNames(
              content.textCentered && 'text-center',
              'column-element',
              content.wideimages && 'wideimages'
            )}
            markdown={content?.col1of3 || ''}
          />
        </div>

        <div className="col-1 mb-8">
          {content.col2Video && (
            <div className="mb-8">
              <VideoComponent
                content={{
                  type: 'video',
                  video: content.col2Video
                }}
                isPreview={isPreview}
                noSection
              />
            </div>
          )}

          <MarkdownComponent
            className={classNames(
              content.textCentered && 'text-center',
              'column-element',
              content.wideimages && 'wideimages'
            )}
            markdown={content?.col2of3 || ''}
          />
        </div>

        <div className="col-1">
          {content.col3Video && (
            <div className="mb-8">
              <VideoComponent
                content={{
                  type: 'video',
                  video: content.col3Video
                }}
                isPreview={isPreview}
                noSection
              />
            </div>
          )}

          <MarkdownComponent
            className={classNames(
              content.textCentered && 'text-center',
              'column-element',
              content.wideimages && 'wideimages'
            )}
            markdown={content?.col3of3 || ''}
          />
        </div>
      </article>
    </Section>
  )
}
