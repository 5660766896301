import React, { FC } from 'react'
import { AccordionComponentContent } from '../../types/page_component_types'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState
} from 'react-accessible-accordion'
import { Section, MarkdownComponent } from '../'
import PlusIcon from '../../assets/icon_plus.svg'
import MinusIcon from '../../assets/icon_divider.svg'

/* https://www.figma.com/file/dlNJahOgzHG8DrFEL3srnt/Design-System?node-id=540%3A10634 */
export const AccordionComponent: FC<{ content: AccordionComponentContent }> = ({
  content
}) => {
  return (
    <Section>
      <Accordion allowMultipleExpanded allowZeroExpanded>
        {content.accordionItems?.map((item, i) => (
          <div key={i}>
            <AccordionItem className="my-8">
              <AccordionItemState>
                {({ expanded }) => {
                  return (
                    <>
                      <AccordionItemHeading>
                        <AccordionItemButton className="flex w-full justify-between">
                          <p className="text-medium mr-10 tracking-wide sm:max-w-[800px] sm:pr-0">
                            {item.heading}
                          </p>
                          <div className="svgWrapper -ml-8 w-6 shrink-0">
                            {expanded ? <MinusIcon /> : <PlusIcon />}
                          </div>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <MarkdownComponent
                          className="my-6 max-w-[327px] md:max-w-[744px]"
                          markdown={item.richText || ''}
                        />
                      </AccordionItemPanel>
                    </>
                  )
                }}
              </AccordionItemState>
            </AccordionItem>
            <div className="h-0.5 w-full bg-blue30"></div>
          </div>
        ))}
      </Accordion>
    </Section>
  )
}
