import { ConsentDataType, IubendaCookieType } from '../../types/consent'

const prepareIubendaCookieObject = (iubendaId: string, purposes: ConsentDataType) => {
  const IubendaCookie: IubendaCookieType = {
    id: iubendaId as string,
    purposes,
    timestamp: new Date().toISOString(),
    version: '1.72.0'
  }

  return IubendaCookie
}

export default prepareIubendaCookieObject
