import React, { FC } from 'react'

export const ArrowRight: FC = () => (
  <svg
    className="fill-current"
    viewBox="0 0 29 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.306 0.307991C21.714 -0.102664 22.3756 -0.102664 22.7836 0.307991L28.694 6.25644C29.102 6.6671 29.102 7.3329 28.694 7.74356L22.7836 13.692C22.3756 14.1027 21.714 14.1027 21.306 13.692C20.898 13.2814 20.898 12.6156 21.306 12.2049L25.484 8H0.999999C0.447715 8 0 7.55228 0 7C0 6.44772 0.447715 6 1 6H25.484L21.306 1.7951C20.898 1.38445 20.898 0.718646 21.306 0.307991Z"
    />
  </svg>
)
