import { FC } from 'react'
import Script from 'next/script'

export const CookieBanner: FC<{ lang: string; policyId: string }> = ({
  lang,
  policyId
}) => {
  const iubendaImplementation = `
    <script type="text/javascript">
      var _iub = _iub || [];
      _iub.csConfiguration = {
        ccpaApplies: false,
        reloadOnConsent: true,
        consentOnContinuedBrowsing: false,
        enableCcpa: true,
        invalidateConsentWithoutLog: true,
        lang: "${lang}",
        perPurposeConsent: true,
        siteId: 1177826,
        cookiePolicyId: "${policyId}",
        banner: {
          acceptButtonCaptionColor: "#EA4E61",
          acceptButtonColor: "#F6F6FA",
          acceptButtonDisplay: true,
          applyStyles: false,
          backgroundColor: "#FFFFFF",
          brandBackgroundColor: "#FFFFFF",
          brandTextColor: "#000000",
          closeButtonRejects: true,
          customizeButtonCaptionColor: "#4D4D4D",
          customizeButtonColor: "#DADADA",
          customizeButtonDisplay: true,
          explicitWithdrawal: true,
          listPurposes: true,
          position: "float-bottom-right",
          rejectButtonCaptionColor: "#FFFFFF",
          rejectButtonColor: "#0073CE",
          rejectButtonDisplay: true,
          textColor: "#000000",
        },
      };
    </script>
  `

  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: iubendaImplementation }}></div>
      <Script src="//cdn.iubenda.com/cs/ccpa/stub.js" />
      <Script src="//cdn.iubenda.com/cs/iubenda_cs.js" />
    </>
  )
}
