import classNames from 'classnames'
import React, { FC } from 'react'

interface ButtonProps {
  label: string
  className?: string
  disabled?: boolean
  link?: string
  onClick?: () => void
  darkBg?: true | false
  [prop: string]: any
}

export const TonnenheldButtonPrimary: FC<ButtonProps> = ({
  label,
  disabled,
  onClick,
  className,
  ...props
}) => {
  return (
    <button
      type="button"
      className={classNames(
        'hover:dropShadowSmall disabled:hover:dropShadowNone bg-[#2667FF] px-8 py-4 uppercase text-white focus:border-red30 active:bg-none disabled:bg-[#05204A7A] disabled:bg-none',
        className
      )}
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      {label}
    </button>
  )
}
