import React from 'react'

interface ErrorBoundaryState {
  hasError: boolean
}

interface ErrorBoundaryProps {
  children: React.ReactNode
}

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  state = { hasError: false }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  render() {
    if (this.state.hasError && process.env.NODE_ENV === 'production') {
      return (
        <div>
          <h1>Something went wrong...</h1>
          <p>You can try to save your changes or reload the page.</p>
        </div>
      )
    }

    return this.props.children
  }
}
