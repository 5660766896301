import React, { FC } from 'react'
import { ServicesCardsComponentContent } from '../../types/page_component_types'
import { Slider, threeColSetting, Section } from '../'
import { ServiceCard } from '../ServiceCard'

/* https://www.figma.com/file/dlNJahOgzHG8DrFEL3srnt/Design-System?node-id=522%3A6943 */
export const ServicesCardsComponent: FC<{
  content: ServicesCardsComponentContent
}> = ({ content }) => {
  return (
    <Section>
      <div className="threeCard">
        <Slider settings={threeColSetting}>
          {content?.cards?.map((card: any, index) => {
            return (
              <ServiceCard
                heading={card?.heading}
                description={card?.description}
                slug={card?.slug}
                image={card?.image}
                readMoreButton={card?.read_more_button_is_displayed}
                key={index}
              />
            )
          })}
        </Slider>
      </div>
    </Section>
  )
}
