import classNames from 'classnames'
import { FC } from 'react'
import { FourColumnsComponentContent } from '../../types/page_component_types'
import { Section, MarkdownComponent } from '..'

/*
 * https://www.figma.com/file/dlNJahOgzHG8DrFEL3srnt/Design-System?node-id=274%3A4863
 * https://www.figma.com/file/dlNJahOgzHG8DrFEL3srnt/Design-System?node-id=274%3A5037
 * to make FourColumnsComponent compatible with old CMS: out of markdown created jsx/html needs to be styled (f.e. images above columns' headings as defined in figma)
 */

export const FourColumnsComponent: FC<{
  content: FourColumnsComponentContent
}> = ({ content }) => {
  return (
    <Section className={classNames(content.backgroundColor)}>
      <article className="grid p-6 md:grid-cols-2 lg:grid-cols-4 md:gap-x-6 md:gap-y-12 lg:gap-x-10 lg:gap-y-0">
        <div className="col-1">
          <MarkdownComponent
            className={classNames(
              content.textCentered && 'text-center',
              'column-element',
              content.wideimages && 'wideimages'
            )}
            markdown={content?.col1of4 || ''}
          />
        </div>
        <div className="col-1">
          <MarkdownComponent
            className={classNames(
              content.textCentered && 'text-center',
              'column-element',
              content.wideimages && 'wideimages'
            )}
            markdown={content?.col2of4 || ''}
          />
        </div>
        <div className="col-1">
          <MarkdownComponent
            className={classNames(
              content.textCentered && 'text-center',
              'column-element',
              content.wideimages && 'wideimages'
            )}
            markdown={content?.col3of4 || ''}
          />
        </div>
        <div className="col-1">
          <MarkdownComponent
            className={classNames(
              content.textCentered && 'text-center',
              'column-element',
              content.wideimages && 'wideimages'
            )}
            markdown={content?.col4of4 || ''}
          />
        </div>
      </article>
    </Section>
  )
}
