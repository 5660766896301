import { FC } from 'react'
import { JobsCollectionContent } from '../../types/page_component_types'
import { JobCard, Section } from '../'

export const JobsCollectionComponent: FC<{
  content: JobsCollectionContent
}> = ({ content }) => {
  return (
    <Section className="flex flex-wrap justify-center gap-6 py-5 lg:justify-start">
      {content.cards?.map((card, i) => (
        <JobCard
          key={i}
          title={card.title || ''}
          jobtype={card.jobtype || ''}
          joblocation={card.joblocation || ''}
          employer={card.employer || ''}
          slug={card.slug || ''}
        />
      ))}
    </Section>
  )
}
