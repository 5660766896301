import Link from 'next/link'
import React, { FC } from 'react'

export interface JobCard {
  title?: string
  jobtype?: string
  joblocation?: string
  employer?: string
  slug: string
}

export const JobCard: FC<
  Pick<
    Required<JobCard>,
    'title' | 'jobtype' | 'joblocation' | 'employer' | 'slug'
  >
> = ({ title, jobtype, joblocation, employer, slug }) => {
  return (
    (<article className="dropShadowSmall group w-full overflow-hidden sm:w-[552px]">
      <Link
        href={slug || ''}
        className="flex h-full flex-col justify-between bg-blue10 p-4">

        <h4 className="mb-8 h-[4.875rem] group-hover:text-turquoise70 md:h-[3.875rem]">
          {title}
        </h4>
        <div>
          <p>{jobtype}</p>
          <p>{joblocation}</p>
          <p className="text-yellow70">{employer}</p>
        </div>

      </Link>
    </article>)
  );
}
