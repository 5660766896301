import { PageTemplateProps } from '../types/page_component_types'
import { getPageComponents } from '../utils/getPageComponent'
import { FC, Fragment } from 'react'

export const PageTemplate: FC<PageTemplateProps> = ({ content, isPreview }) => {
  return (
    <>
      {content?.map((pageComponentContent, index) => {
        return (
          <Fragment key={index}>
            {getPageComponents({
              content: pageComponentContent,
              isPreview: isPreview || false
            })}
          </Fragment>
        )
      })}
    </>
  )
}
