import React, { FC } from 'react'
import classNames from 'classnames'
import { TimerComponentContent } from '../../types/page_component_types'
import Countdown, { CountdownRendererFn, zeroPad } from 'react-countdown'
import { Section } from '../'

/* https://www.figma.com/file/dlNJahOgzHG8DrFEL3srnt/Design-System?node-id=274%3A3500 */
export const TimerComponent: FC<{
  content: TimerComponentContent
}> = ({ content }) => {
  const starttime = content?.eventItem?.time?.starttime
  if (!starttime) return null
  return (
    <Section>
      <Countdown date={starttime} renderer={renderer} />
    </Section>
  )
}

// Renderer callback with condition
const renderer: CountdownRendererFn = ({ days, hours, minutes, seconds }) => {
  return (
    <ul className="mx-auto flex max-w-[220px] flex-wrap justify-center gap-4 sm:max-w-full md:gap-6">
      <Card timeNr={zeroPad(days)} timeName="days" active={days != 0} />
      <Card
        timeNr={zeroPad(hours)}
        timeName="hours"
        active={days == 0 && hours != 0}
      />
      <Card
        timeNr={zeroPad(minutes)}
        timeName="minutes"
        active={days == 0 && hours == 0 && minutes != 0}
      />
      <Card
        timeNr={zeroPad(seconds)}
        timeName="seconds"
        active={days == 0 && hours == 0 && minutes == 0 && seconds != 0}
      />
    </ul>
  )
}

interface CardProps {
  timeNr: string
  timeName: string
  active?: boolean
}

const Card: FC<CardProps> = ({ timeNr, timeName, active }) => (
  <li className="dropShadowBig">
    <div className="clipLogo flex h-[6.25rem] w-[6.25rem] flex-col items-center justify-center bg-blue10 px-2 md:h-[9.5rem] md:w-[9.5rem]">
      <span
        className={classNames(
          active ? 'textGradient orangeRed' : '',
          'text-5xl font-medium leading-base md:text-[3.5rem]'
        )}
      >
        {timeNr}
      </span>
      <h5>{timeName}</h5>
    </div>
  </li>
)
