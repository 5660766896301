import React, { FC } from 'react'

interface FormatDateTimePeriodProps {
  startDate: Date
  endDate: Date
}

const dayOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric'
}

const timeOptions: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
  hour12: false
}

const locale = 'en-DE'

export const getFormattedDateTimePeriodString = (
  startDate: Date,
  endDate: Date
): [date: string, time: string] => {
  const startAndEndDateAreTheSame =
    startDate.getFullYear() === endDate.getFullYear() &&
    startDate.getMonth() === endDate.getMonth() &&
    startDate.getDate() === endDate.getDate()

  const startAndEndMonthAreTheSame =
    startDate.getFullYear() === endDate.getFullYear() &&
    startDate.getMonth() === endDate.getMonth()

  const startAndEndYearAreTheSame =
    startDate.getFullYear() === endDate.getFullYear()

  if (startAndEndDateAreTheSame) {
    return [
      startDate.toLocaleString(locale, dayOptions),
      `${startDate.toLocaleString(
        locale,
        timeOptions
      )} - ${endDate.toLocaleString(locale, timeOptions)}`
    ]
  }

  if (startAndEndMonthAreTheSame) {
    return [
      `${startDate.toLocaleString(locale, {
        day: 'numeric'
      })} - ${endDate.toLocaleString(locale, dayOptions)}`,
      `Starts at ${startDate.toLocaleString(locale, timeOptions)}`
    ]
  }
  if (startAndEndYearAreTheSame) {
    return [
      `${startDate.toLocaleString(locale, {
        day: 'numeric',
        month: 'long'
      })} - ${endDate.toLocaleString(locale, dayOptions)}`,
      `Starts at ${startDate.toLocaleString(locale, timeOptions)}`
    ]
  }
  return [
    `${startDate.toLocaleString(locale, dayOptions)} - ${endDate.toLocaleString(
      locale,
      dayOptions
    )}`,
    `Starts at ${startDate.toLocaleString(locale, timeOptions)}`
  ]
}

export const FormatDateTimePeriod: FC<FormatDateTimePeriodProps> = ({
  startDate,
  endDate
}) => {
  return (
    <>
      {getFormattedDateTimePeriodString(startDate, endDate).map((line, i) => {
        return <p key={i}>{line}</p>
      })}
    </>
  )
}
