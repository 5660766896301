import React, { FC } from 'react'
import { EventCard as TEventCard } from '../types/page_component_types'
import { getFormattedDateTimePeriodString } from './Time'
import hackathonIcon from '../assets/icon_hackathon.png'
import meetupIcon from '../assets/icon_meetup.png'
import workshopIcon from '../assets/icon_workshop.png'
import programmeIcon from '../assets/icon_programme.png'
import Image from 'next/legacy/image'
import { StaticImageData } from 'next/image';
import Link from 'next/link'

const icons: Record<Exclude<TEventCard['typeOfEvent'], undefined>, StaticImageData> = {
  meetup: meetupIcon,
  hackathon: hackathonIcon,
  workshop: workshopIcon,
  programme: programmeIcon
}

export const EventCard: FC<
  Pick<Required<TEventCard>, 'typeOfEvent' | 'title' | 'timeFrame' | 'slug'>
> = ({ typeOfEvent, title, timeFrame, slug }) => {
  const eventDayString =
    timeFrame.starttime &&
    timeFrame.endtime &&
    getFormattedDateTimePeriodString(
      new Date(timeFrame.starttime),
      new Date(timeFrame.endtime)
    )[0]

  return (
    <Link href={slug || ''}>
      <article className="dropShadowSmall group flex w-full flex-col overflow-hidden bg-blue10 py-6 px-4 md:h-[20.7rem]">
        <div className="mb-2 w-10 md:w-[3.75rem]">
          {icons[typeOfEvent] && (
            <Image src={icons[typeOfEvent]} alt={`${typeOfEvent} icon`} />
          )}
        </div>
        <h5 className="mb-8 tracking-[0.125rem] md:mb-4">{typeOfEvent}</h5>
        <time dateTime={timeFrame.starttime} className="mb-[0.125rem] md:mb-1">
          {eventDayString || ''}
        </time>
        <h4 className="line-clamp-4 group-hover:text-turquoise70">{title}</h4>
      </article>
    </Link>
  )
}
