import Image from 'next/legacy/image'
import React, { FC } from 'react'
import { EventBannerComponentContent } from '../../types/page_component_types'
import {
  Section,
  Slider,
  ButtonPrimary,
  ButtonTertiary,
  MarkdownComponent
} from '../'
import { FormatDateTimePeriod } from '../Time'
import classNames from 'classnames'
import { useTranslations } from 'next-intl'

interface EventBannerProps {
  title: string
  address: string
  link: string
  label: string
  src: string
  alt: string
  startDate?: Date
  endDate?: Date
  theme: string
}

interface Theme {
  next?: string
  later?: string
  past?: string
}

export const EventBanner: FC<EventBannerProps> = ({
  alt,
  title,
  address,
  link,
  label,
  src,
  startDate,
  endDate,
  theme
}) => {
  const t = useTranslations()

  const themes = {
    next: ' orangeRed',
    later: 'yellowBlue',
    past: 'bg-transparent'
  }
  return (
    <div className="flex h-[43.75rem] select-text flex-col items-center gap-6 sm:h-[52rem] md:h-[56.25rem] lg:h-[44.5rem] lg:flex-row lg:gap-x-16">
      <article className="w-full md:w-2/5">
        <h3
          className={classNames(
            themes[theme as keyof Theme],
            theme === 'past' ? 'bg-blue100 text-white' : 'textGradient'
          )}
        >
          {title}
        </h3>
        <div
          className={classNames('mt-3 w-6 p-0.5', themes[theme as keyof Theme])}
        ></div>

        <div className="pt-8 text-white">
          {startDate && endDate && (
            <FormatDateTimePeriod startDate={startDate} endDate={endDate} />
          )}
        </div>
        <MarkdownComponent
          className="select-text pb-16 text-white"
          markdown={address || ''}
        />
        {theme === 'past' ? (
          <ButtonTertiary link={link} label={label || t('read_more')} darkBg />
        ) : (
          <ButtonPrimary label={label || t('read_more')} link={link} />
        )}
      </article>
      <figure className="clipImage relative order-first h-full w-full lg:order-last lg:w-3/5">
        {src && (
          <Image
            className="clipImage"
            src={src}
            alt={alt}
            objectFit="cover"
            width={751}
            height={559}
          />
        )}
        {theme === 'past' && (
          <>
            <span className="absolute inset-0 h-full w-full bg-blue100 mix-blend-color"></span>
            <figcaption className="absolute top-1/6 -right-10 hidden h-5 w-36 rotate-[55deg] bg-red70 text-center text-sm uppercase tracking-wide text-white shadow-dropShadowRibbon lg:block">
              ended
            </figcaption>
          </>
        )}
      </figure>
    </div>
  )
}

export const EventBannerComponent: FC<{
  content: EventBannerComponentContent
}> = ({ content }) => {
  const theme = ['next', 'later', 'next']

  interface GetTheme {
    date?: string
    index: number
  }

  const getTheme = ({ date, index }: GetTheme) => {
    if (date && new Date(date) < new Date()) return 'past'
    return theme[index]
  }

  return (
    <Section type={content.type} darkBackground>
      <div className="banner">
        <Slider darkBackground>
          {content.bannerItems?.map((card, index) => {
            return (
              <EventBanner
                key={index}
                title={card.title || ''}
                startDate={
                  card.time?.starttime
                    ? new Date(card.time?.starttime)
                    : undefined
                }
                endDate={
                  card.time?.endtime ? new Date(card.time?.endtime) : undefined
                }
                alt={card.image?.alt || ''}
                src={card.image?.src || ''}
                address={card.location?.address || ''}
                link={card?.button?.link || card.slug || ''}
                label={card?.button?.label || ''}
                theme={getTheme({ date: card.time?.endtime, index })}
              />
            )
          })}
        </Slider>
      </div>
    </Section>
  )
}
