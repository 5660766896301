import React, { FC } from 'react'
import { TonnenheldImageTextBannerComponentContent } from '../../types/page_component_types'
import Image from 'next/legacy/image'
import classNames from 'classnames'
import ReactMarkdown from 'react-markdown'
import { TonnenheldSection } from '../TonnenheldSection'

/* use code tag to style highlighted text yellow (richText)
https://www.figma.com/file/dlNJahOgzHG8DrFEL3srnt/Design-System?node-id=303%3A3998
*/

export const TonnenheldImageTextBannerComponent: FC<{
  content: TonnenheldImageTextBannerComponentContent
}> = ({ content }) => {
  return (
    <TonnenheldSection type={content.type} textFirst={true}>
      <div className="flex flex-col items-center py-16 md:flex-row md:gap-x-16">
        <ReactMarkdown
          className={classNames(
            'contentStyles tonnenheldImageTextBannerContentStyles mb-5 w-full md:mb-10 md:w-2/5'
          )}
        >
          {content.richText || ''}
        </ReactMarkdown>
        <figure
          className={classNames('relative h-full w-full md:mb-0 md:w-3/5')}
        >
          {content.image?.src && (
            <Image
              src={content.image?.src}
              alt={content.image?.alt || ''}
              width={750}
              height={558}
              objectFit="cover"
            />
          )}
        </figure>
      </div>
    </TonnenheldSection>
  )
}
