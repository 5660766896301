import { FC } from 'react'
import { TestimonialsComponentContent } from '../../types/page_component_types'
import { Section, Slider, TestimonialCard } from '../'

/* https://www.figma.com/file/dlNJahOgzHG8DrFEL3srnt/Design-System?node-id=303%3A5769 */
export const TestimonialsComponent: FC<{
  content: TestimonialsComponentContent
}> = ({ content }) => {
  return (
    <Section type={content.type} fullWidth>
      <div className=" testimonial clipBg bg-blue10 px-6 pt-10 pb-16 md:w-full md:py-20">
        <Slider>
          {content.testimonialItems?.map((card, i) => (
            <TestimonialCard
              key={i}
              alt={card.image?.alt || ''}
              text={card.richText}
              name={card.name}
              title={card.titleOrganisation}
              src={card.image?.src || ''}
            />
          ))}
        </Slider>
      </div>
    </Section>
  )
}
