import { FC } from 'react'
import {
  Button,
  PlaceholderCard as PlaceholderCarType,
  ServicesCollectionCard,
  ServicesCollectionContent
} from '../../types/page_component_types'
import { Section, ServiceCard, ButtonPrimary, ButtonSecondary } from '../'
import classNames from 'classnames'

export const ServicesCollectionCardsComponent: FC<{
  content: ServicesCollectionContent
}> = ({ content }) => {
  const servicesCards = content.cards
  const placeholderCards = content.placeholderCards || []

  const cardsData: Array<ServicesCollectionCard | PlaceholderCarType> = [
    ...servicesCards,
    ...placeholderCards
  ]
  const cards = cardsData.map(
    (cardData: ServicesCollectionCard | PlaceholderCarType, index) => {
      const numServicesCards = servicesCards.length

      const placeholderCard =
        index >= numServicesCards ? (cardData as PlaceholderCarType) : null
      const serviceCard =
        index < numServicesCards ? (cardData as ServicesCollectionCard) : null

      if (placeholderCard) {
        const buttonData = {
          label: placeholderCard.button?.label || '',
          link: placeholderCard.button?.link || ''
        }
        return (
          <PlaceholderCard
            key={index}
            heading={placeholderCard.heading || ''}
            button={buttonData}
            numServicesCards={numServicesCards}
            placeholderCardOrderIndex={index - numServicesCards}
            typeOfButton={placeholderCard.button?.buttonType || 'primary'}
          />
        )
      }
      if (serviceCard) {
        return (
          <ServiceCard
            isCollectionCard
            key={serviceCard.guid}
            heading={serviceCard?.heading || ''}
            description={serviceCard.description || ''}
            image={serviceCard.image || {}}
            slug={serviceCard.slug || ''}
            readMoreButton={serviceCard.read_more_button_is_displayed || false}
          />
        )
      }
    }
  )

  return (
    <Section>
      <div className="grid gap-x-6 gap-y-8 md:grid-cols-2 lg:grid-cols-3">
        {cards}
      </div>
    </Section>
  )
}

const PlaceholderCard: FC<{
  numServicesCards: number
  heading: string
  button: Required<Button>
  placeholderCardOrderIndex: number
  typeOfButton: 'primary' | 'secondary'
}> = ({
  numServicesCards,
  heading,
  button,
  placeholderCardOrderIndex,
  typeOfButton
}) => {
  return (
    <article
      className={classNames(
        'dropShadowSmall hidden h-[11.25rem] w-full flex-col justify-center bg-white px-2 py-4 sm:h-[35.5rem] md:px-4 md:py-8',
        getResponsiveCSSDisplayPropertyValuesForPlaceholderCards(
          numServicesCards,
          placeholderCardOrderIndex
        )
      )}
    >
      <div className="mb-4 flex h-full flex-col-reverse sm:h-auto sm:text-center md:mb-8 md:block">
        <h6>{heading}</h6>
      </div>
      <div className="sm:mx-auto">
        {typeOfButton === 'primary' ? (
          <ButtonPrimary label={button.label} link={button.link} />
        ) : (
          <ButtonSecondary label={button.label} link={button.link} />
        )}
      </div>
    </article>
  )
}

const getResponsiveCSSDisplayPropertyValuesForPlaceholderCards = (
  numCards: number,
  placeholderCardOrderIndex: number
): [mdBreakPointValue: string, lgBreakPointValue: string] => {
  const numCardsByRowMd = 2
  const numCardsByRowLg = 3

  const mdBreakPointValue =
    numCardsByRowMd - (numCards % numCardsByRowMd) >
      placeholderCardOrderIndex && numCards % numCardsByRowMd !== 0
      ? 'md:flex'
      : 'md:hidden'
  const lgBreakPointValue =
    numCardsByRowLg - (numCards % numCardsByRowLg) >
      placeholderCardOrderIndex && numCards % numCardsByRowLg !== 0
      ? 'lg:flex'
      : 'lg:hidden'
  return [mdBreakPointValue, lgBreakPointValue]
}
