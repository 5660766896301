import classNames from 'classnames'
import { FC } from 'react'
import { TwoColumnsComponentContent } from '../../types/page_component_types'
import { Section, MarkdownComponent } from '../'
import { VideoComponent } from './VideoComponent'

/*
 * https://www.figma.com/file/dlNJahOgzHG8DrFEL3srnt/Design-System?node-id=274%3A5030
 * https://www.figma.com/file/dlNJahOgzHG8DrFEL3srnt/Design-System?node-id=274%3A5036
 * to make TwoColumnsComponent compatible with old CMS: out of markdown created html needs to be styled (f.e. images above columns' headings as defined in figma)
 */
export const TwoColumnsComponent: FC<{
  content: TwoColumnsComponentContent
  isPreview: boolean
  darkBackground?: boolean
}> = ({ content, isPreview, darkBackground = false }) => {
  return (
    <Section darkBackground={darkBackground}>
      <article className="grid-cols-2 gap-6 md:grid">
        <div className="col-1 mb-8">
          {content.col1Video && (
            <div className="mb-8">
              <VideoComponent
                content={{
                  type: 'video',
                  video: content.col1Video
                }}
                isPreview={isPreview}
                noSection
              />
            </div>
          )}

          <MarkdownComponent
            className={classNames(
              content.textCentered && 'text-center',
              'column-element',
              content.wideimages && 'wideimages'
            )}
            markdown={content?.col1of2 || ''}
          />
        </div>

        <div className="col-1">
          {content.col2Video && (
            <div className="mb-8">
              <VideoComponent
                content={{
                  type: 'video',
                  video: content.col2Video
                }}
                isPreview={isPreview}
                noSection
              />
            </div>
          )}

          <MarkdownComponent
            className={classNames(
              content.textCentered && 'text-center',
              'column-element',
              content.wideimages && 'wideimages'
            )}
            markdown={content?.col2of2 || ''}
          />
        </div>
      </article>
    </Section>
  )
}
