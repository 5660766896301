import React, { FC, useState } from 'react'
import { GuestsComponentContent } from '../../types/page_component_types'
import { Section } from '../'
import classNames from 'classnames'
import Image from 'next/legacy/image'

/* https://www.figma.com/file/dlNJahOgzHG8DrFEL3srnt/Design-System?node-id=590%3A10600 */
export const GuestsComponent: FC<{ content: GuestsComponentContent }> = ({
  content
}) => {
  return (
    <Section>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 justify-items-center sm:min-h-[32.5rem]  md:min-h-[41.75rem] lg:min-h-[44.875rem]">
        {content.people?.map((guest, index) => {
          return (
            <Guest
              key={index}
              imageSrc={guest.image?.src}
              imageAlt={guest.image?.alt}
              name={guest.name || ''}
              role={guest.role || ''}
              title={guest.title || ''}
            />
          )
        })}
      </div>
    </Section>
  )
}

interface roleColorType {
  expert: string
  jury: string
  mentor: string
}

const Guest: FC<{
  imageSrc?: string
  imageAlt?: string
  name: string
  role: string
  title: string
}> = ({ imageSrc, imageAlt, name, role, title }) => {
  const roleColor: roleColorType = {
    expert: 'text-blue70',
    jury: 'text-red70',
    mentor: 'text-turquoise70'
  }
  return (
    <div className="flex w-80 flex-col items-center pb-4">
      <figure className="clipImage relative mb-6 block w-52 after:absolute after:top-0 after:left-0 after:h-full after:w-full after:bg-blue100 after:mix-blend-color after:content-[''] hover:after:bg-transparent sm:w-40 md:mb-10">
        {imageSrc && (
          <Image
            src={imageSrc}
            alt={imageAlt || ''}
            width={200}
            height={224}
            layout="responsive"
            objectFit="cover"
            loading="eager"
          />
        )}
      </figure>
      <div className="w-full text-center font-bold">{name}</div>
      <div
        className={classNames(
          'my-3 rounded-2xl bg-blue10 px-2 py-1 text-center text-small',
          roleColor[role as keyof roleColorType]
        )}
      >
        {role}
      </div>
      <div className="mb-6 w-full text-center text-small md:mb-4">{title}</div>
    </div>
  )
}
