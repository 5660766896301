import { FC } from 'react'

import Image from 'next/legacy/image'
import { MarkdownComponent } from './'
import RedQuote from '../assets/icon_redquote.svg'
import classNames from 'classnames'

interface TestimonialCardProps {
  text: string
  name?: string
  title?: string
  src?: string
  alt?: string
}

export const TestimonialCard: FC<TestimonialCardProps> = ({
  alt,
  text,
  name,
  title,
  src
}) => {
  return (
    <div className="mx-auto max-w-[70.5rem] content-center items-center gap-6 py-20 md:grid md:w-full md:grid-cols-3 md:gap-12 md:py-14">
      {src && (
        <figure className="relative col-span-1 m-auto mb-6 max-w-xs md:m-0 md:mb-0">
          <Image
            className="clipImage"
            src={src}
            alt={alt || ''}
            layout="responsive"
            width={368}
            height={409}
            objectFit="cover"
          />

          <div className="svgWrapper absolute  bottom-2 right-2 h-[82px] w-[60px] md:-bottom-6 md:right-4 md:h-[126px] md:w-[92px]">
            <RedQuote />
          </div>
        </figure>
      )}
      <article
        className={classNames(src ? 'col-span-2' : 'col-span-3 text-center')}
      >
        <MarkdownComponent className="mb-6 md:mb-8" markdown={text || ''} />
        <p className="font-bold">{name}</p>
        <p>{title}</p>
      </article>
    </div>
  )
}
