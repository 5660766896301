export const setCookie = (
  name: string,
  value: any,
  days: number,
  setDomain = false
) => {
  const rawCookie = encodeURIComponent(JSON.stringify(value))

  const expiresDate = new Date()
  expiresDate.setTime(expiresDate.getTime() + days * 24 * 60 * 60 * 1000)

  const expiresDateFormatted = 'expires=' + expiresDate.toUTCString()

  let domain = ''
  if (setDomain) {
    const locationOrigin = window?.location?.origin
    if (locationOrigin) {
      domain = getMainDomain(locationOrigin)
      domain = ` domain=.${domain};`
    }
  }

  document.cookie = `${name}=${rawCookie}; ${expiresDateFormatted};${domain} SameSite=None; Secure`
}

function getMainDomain(origin: string) {
  const hostname = new URL(origin).hostname
  const parts = hostname.split('.')
  if (parts.length >= 2) {
    return parts.slice(-2).join('.')
  }
  return hostname // Return original hostname if parsing fails
}
