import { FC } from 'react'
import classNames from 'classnames'

export const TonnenheldSection: FC<{
  fullWidth?: boolean
  className?: string
  darkBackground?: boolean
  textFirst?: boolean
  type?: string
  children: React.ReactNode
}> = ({ children, fullWidth, darkBackground, className, textFirst, type }) => {
  const isWideImgText = type === 'imageTextBanner' && fullWidth
  const isEventBanner = type === 'eventBanner'
  const hasTonnenheldBackground = type === 'tonnenheld_imageTextBanner'

  return (
    <section
      className={classNames(
        'mb-5 py-5 md:mb-10 md:py-10',
        isEventBanner && 'mb-8 md:mb-14',
        darkBackground && 'dark-background bg-blue100 text-white',
        hasTonnenheldBackground && 'bg-[#4FB0AD]',
        !darkBackground && !hasTonnenheldBackground && 'bg-white text-blue100'
      )}
    >
      <div
        className={classNames(
          'mx-auto',
          type !== 'testimonials' && 'pr-6 pl-6 lg:pr-0 lg:pl-0',
          fullWidth ? 'max-w-[90rem]' : 'max-w-[70.5rem]',
          isWideImgText && textFirst && 'md:pr-0 md:pl-[5rem] lg:pl-[10rem]',
          isWideImgText && !textFirst && 'md:pr-[5rem] md:pl-0 lg:pr-[10rem]',
          className
        )}
      >
        {children}
      </div>
    </section>
  )
}
