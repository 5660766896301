import classNames from 'classnames'
import React, { FC } from 'react'
import ReactMarkdown, { Components } from 'react-markdown'
import { EventAgendaComponentContent } from '../../types/page_component_types'
import { DateRange, Link } from '../../types/shared_types'
import { ButtonTertiary, Section, MarkdownComponent } from '../'
import { getFormattedDateTimePeriodString } from '../Time'

/* https://www.figma.com/file/dlNJahOgzHG8DrFEL3srnt/Design-System?node-id=270%3A2860 */
export const EventAgendaComponent: FC<{
  content: EventAgendaComponentContent
}> = ({ content }) => {
  return (
    <Section
      sectionClassName="bg-blue10 py-0 md:py-0"
      className="pb-6 pt-10 md:py-20"
    >
      {content.agendaItems?.map((item, index) => {
        const startTime = item.time?.starttime && new Date(item.time.starttime)
        const endTime = item.time?.endtime && new Date(item.time.endtime)
        const dateRange: DateRange | undefined =
          startTime && endTime ? { start: startTime, end: endTime } : undefined
        const cta: Link | undefined =
          item.button?.label && item.button.link
            ? {
                label: item.button.label,
                href: item.button.link
              }
            : undefined
        return (
          <EventAgendaItem
            key={index}
            dateRange={dateRange}
            heading={item.heading || ''}
            cta={cta}
            description={item.richText || ''}
          />
        )
      })}
    </Section>
  )
}

const EventAgendaItem: FC<{
  dateRange?: DateRange
  heading: string
  cta?: Link
  description: string
}> = ({ dateRange, heading, cta, description }) => {
  const reactMarkdownPToSpanComponentMapping: Partial<Components> = {
    p({ node, className, children, ...props }) {
      return (
        <span className={classNames('block', className)} {...props}>
          {children}
        </span>
      )
    }
  }

  return (
    <article className="border-b border-blue100 pt-5 pb-8 first:pt-0 md:border-b-2 md:py-12">
      {dateRange && (
        <time
          dateTime={dateRange.start.toDateString()}
          className="mb-6 block text-small last:mb-0 "
        >
          {getFormattedDateTimePeriodString(dateRange.start, dateRange.end).map(
            (line, i) => {
              return (
                <span key={i} className="mb-1 block last:mb-0">
                  {line}
                </span>
              )
            }
          )}
        </time>
      )}
      <p className="orangeRed textGradient mb-6 font-bold  last:mb-0">
        <ReactMarkdown components={reactMarkdownPToSpanComponentMapping}>
          {heading}
        </ReactMarkdown>
      </p>
      {cta && (
        <ButtonTertiary
          link={cta?.href || ''}
          label={cta?.label || ''}
          className="mb-6"
        />
      )}
      <MarkdownComponent markdown={description || ''} />
    </article>
  )
}
