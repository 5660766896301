import React, { FC } from 'react'
import { FullWidthImageComponentContent } from '../../types/page_component_types'
import { Section } from '../'
import Image from 'next/legacy/image'

/* https://www.figma.com/file/dlNJahOgzHG8DrFEL3srnt/Design-System?node-id=413%3A5499 */
export const FullWidthImageComponent: FC<{
  content: FullWidthImageComponentContent
}> = ({ content }) => {
  return (
    <Section>
      {content.image?.src && (
        <div className="image-container w-full">
          <Image
            src={content.image?.src || ''}
            alt={content.image?.alt || ''}
            className="image mx-auto !max-h-[634px]"
            layout="fill"
          />
        </div>
      )}
    </Section>
  )
}
