import Image from 'next/legacy/image'
import React, { FC } from 'react'
import { FullWidthImageComponentContent } from '../../types/page_component_types'
import { Section } from '..'

/* https://www.figma.com/file/dlNJahOgzHG8DrFEL3srnt/Design-System?node-id=413%3A5499 */
export const TonnenheldFullWidthImageComponent: FC<{
  content: FullWidthImageComponentContent
}> = ({ content }) => {
  return (
    <Section>
      {content.image?.src && (
        //  https://stackoverflow.com/questions/66353475/how-to-use-image-component-in-next-js-with-unknown-width-and-height
        // here is no good solution with the nextjs Image, so as recommended in post I use the html img.
        <div className="relative flex w-full justify-center">
          <img
            src={content.image?.src || ''}
            alt={content.image?.alt || ''}
            className="object-contain"
          />
        </div>
      )}
    </Section>
  )
}
