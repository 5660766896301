import Link from 'next/link'
import { FC } from 'react'
import { TonnenheldSubmitButtonContent } from '../../types/page_component_types'
import { TonnenheldButtonPrimary } from '../TonnenheldButtons'
import { Section } from '../Section'

/* https://www.figma.com/file/dlNJahOgzHG8DrFEL3srnt/Design-System?node-id=8%3A78 */
export const TonnenheldSubmitButtonComponent: FC<{
  content: TonnenheldSubmitButtonContent
}> = ({ content }) => {
  return (
    (<Section>
      <div className="flex justify-center text-button">
        <Link href={content.button?.link || ''} passHref>

          <TonnenheldButtonPrimary label={content.button?.label || ''} />

        </Link>
      </div>
    </Section>)
  );
}
