import { NextRouter } from 'next/router'
import { useRouter } from 'next/compat/router'
import { useCallback, useEffect, useState } from 'react'
import { useDeepCompareEffect } from 'react-use'
import { scrollIntoView } from 'seamless-scroll-polyfill'
import {
  GetEventCollectionCardsResponseJSON,
  Locale
} from '../../types/shared_types'

export interface ApiUrlOptions {
  path: string
  currentPageQueryStringKey: string
  additionalQueryParams?: { [queryParam: string]: string }
}

export const usePaginatedCardsData = <T>(
  initialData: T[],
  numItemsPerPage: number,
  numTotalItems: number,
  apiUrlOptions: ApiUrlOptions,
  topRef: React.RefObject<HTMLElement>,
  locale?: Locale
) => {
  const pageCount = Math.ceil(numTotalItems / numItemsPerPage)
  const isValidPageNumber = useCallback(
    (pageNumber: number) => {
      return pageNumber > 0 && pageNumber <= pageCount
    },
    [pageCount]
  )

  const router = useRouter() as NextRouter | undefined
  const currentPageQueryStringValue =
    router?.query[apiUrlOptions.currentPageQueryStringKey]

  const currentPageQueryStringParamValueExists =
    currentPageQueryStringValue !== undefined

  let currentPageNumber = 1
  if (
    typeof currentPageQueryStringValue === 'string' &&
    isValidPageNumber(parseInt(currentPageQueryStringValue))
  ) {
    currentPageNumber = parseInt(currentPageQueryStringValue)
  } else if (currentPageQueryStringValue !== undefined) {
    currentPageNumber = pageCount
  }

  const initialCardItemsData = Array.from<undefined, T[]>(
    { length: pageCount },
    _ => []
  )
  initialCardItemsData[currentPageNumber - 1] = initialData

  const [cardItemsData, setCardItemsData] =
    useState<T[][]>(initialCardItemsData)
  const [currentPageIndex, setCurrentPageIndex] = useState(
    currentPageNumber - 1
  )
  const [isLoading, setIsLoading] = useState(false)

  const currentPageCardsData = cardItemsData[currentPageIndex]

  useEffect(() => {
    const currentPageQueryStringParamValueIsCorrect =
      typeof currentPageQueryStringValue === 'string' &&
      currentPageNumber === parseInt(currentPageQueryStringValue)

    if (
      currentPageQueryStringParamValueExists &&
      !currentPageQueryStringParamValueIsCorrect
    ) {
      router?.replace(
        {
          query: {
            ...router.query,
            [apiUrlOptions.currentPageQueryStringKey]: pageCount
          }
        },
        undefined,
        { scroll: false, shallow: true }
      )
    }
  }, [
    apiUrlOptions.currentPageQueryStringKey,
    currentPageQueryStringValue,
    router,
    isValidPageNumber,
    pageCount,
    currentPageNumber,
    currentPageQueryStringParamValueExists
  ])

  useDeepCompareEffect(() => {
    const getNewCardItemData = async (newPageIndex: number) => {
      setIsLoading(true)
      try {
        const offset = (newPageIndex * numItemsPerPage).toString()
        const sort = 'desc'
        const pageSize = numItemsPerPage.toString()
        const queryParamsObject: { [paramKey: string]: string } = {
          offset,
          sort,
          pageSize,
          ...apiUrlOptions.additionalQueryParams
        }
        if (locale) {
          queryParamsObject.lang = locale
        }

        const url =
          apiUrlOptions.path + '?' + new URLSearchParams(queryParamsObject)
        const res = await fetch(url, { method: 'GET' })
        const { data } =
          (await res.json()) as GetEventCollectionCardsResponseJSON

        const newCardItemsData = cardItemsData
        newCardItemsData[newPageIndex] = data.cards as T[]

        setCardItemsData(newCardItemsData)
        setCurrentPageIndex(newPageIndex)
      } catch (error) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('LOADING ERROR: ', error)
        }
      }
      setIsLoading(false)
    }
    const newPageIndex = currentPageNumber - 1

    if (cardItemsData[newPageIndex].length === 0) {
      getNewCardItemData(newPageIndex)
    }
    if (cardItemsData[newPageIndex].length !== 0) {
      setCurrentPageIndex(newPageIndex)
    }
  }, [
    currentPageQueryStringValue,
    apiUrlOptions.path,
    cardItemsData,
    pageCount,
    numItemsPerPage,
    currentPageNumber,
    apiUrlOptions.additionalQueryParams
  ])

  useEffect(() => {
    if (currentPageQueryStringParamValueExists && topRef?.current) {
      scrollIntoView(topRef.current, { behavior: 'smooth', block: 'start' })
    }
  }, [
    currentPageQueryStringValue,
    topRef,
    currentPageQueryStringParamValueExists
  ])

  const handleNewPage = (selectedItem: { selected: number }): void => {
    router?.push(
      {
        pathname: router.pathname,
        query: {
          ...router.query,
          [apiUrlOptions.currentPageQueryStringKey]: String(
            selectedItem.selected + 1
          )
        }
      },
      undefined,
      { scroll: false, shallow: true }
    )
  }

  return {
    currentPageCardsData,
    currentPageNumber,
    isLoading,
    pageCount,
    handleNewPage
  }
}
