import { FC } from 'react'
import { BlogsCollectionContent } from '../../types/page_component_types'
import { BlogCard, Section } from '../'

export const BlogsCollectionComponent: FC<{
  content: BlogsCollectionContent
}> = ({ content }) => {
  return (
    <Section className="mx-auto flex flex-wrap justify-center gap-6 md:w-[792px] md:justify-start lg:w-full">
      {content.cards?.map((card, i) => (
        <BlogCard
          key={i}
          title={card.title || ''}
          author={card.author || ''}
          publicationDate={card.publicationDate || ''}
          excerpt={card.excerpt || ''}
          src={card.image?.src || ''}
          alt={card.image?.alt || ''}
          slug={card.slug || ''}
        />
      ))}
    </Section>
  )
}
