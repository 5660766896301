import { CSSProperties, FC } from 'react'
import { InteractiveCardsComponentContent } from '../../types/page_component_types'
import classNames from 'classnames'
import { Section } from '../Section'

export const InteractiveCardsComponent: FC<{
  content: InteractiveCardsComponentContent
}> = ({ content }) => {
  const hasCards = content.cards && content.cards.length > 0

  if (!hasCards) {
    return null
  }

  return (
    <Section>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 sm:px-4">
        {content.cards!.map((card, index) => {
          let style: CSSProperties | undefined = undefined
          if (card.image?.src) {
            style = { backgroundImage: `url(${card.image.src})` }
          }

          return (
            <div
              key={index}
              className="relative group text-white p-4 bg-blue100 rounded-xl h-[300px] overflow-hidden transition-all duration-300 ease-in-out bg-cover bg-center"
              style={style}
            >
              {/* Default Card Content */}
              {card.plainTitle && (
                <p className="text-base font-thin pb-2">{card.plainTitle}</p>
              )}
              {card.boldTitle && <p className="text-lg">{card.boldTitle}</p>}

              {/* Hover Overlay */}
              <div className="absolute p-4 inset-0 justify-center bg-white rounded-xl text-blue100 opacity-0 overflow-auto group-hover:opacity-100 border border-blue100 transition-all duration-300 ease-in-out">
                {card.hoverTitle && (
                  <p className="text-lg pb-4">{card.hoverTitle}</p>
                )}

                <p className="text-sm whitespace-break-spaces">
                  {card.hoverText}
                </p>
              </div>
            </div>
          )
        })}
      </div>
    </Section>
  )
}
