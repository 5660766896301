import { ConsentDataType } from '../../types/consent'
import { AppContextType } from '../context'
import prepareIubendaCookieObject from './prepare_iubenda_cookie_object'
import { setCookie } from './set_cookie'

const updateVideoOptIn = (
  cookieConsent: AppContextType | undefined,
  iubendaId: string
): void => {
  if (cookieConsent && cookieConsent.consentData) {
    // Cookie exists, need to update the experience cookie
    const newPurposesArray = {
      ...cookieConsent.consentData,
      // Set User Experience Cookie to true
      3: true
    }

    // Update App Context
    cookieConsent.setConsentData(newPurposesArray)

    // Update Cookie
    const IubendaCookie = prepareIubendaCookieObject(
      iubendaId,
      newPurposesArray
    )
    setCookie(`_iub_cs-${iubendaId}`, IubendaCookie, 365, true)
  } else if (cookieConsent) {
    // Cookie does not exist, need to create the cookie and allow what's needed
    const newPurposesArray: ConsentDataType = {
      1: true, // necessary cookie
      3: true, // user experience cookie
      4: true, // measurement cookie
      5: false // marketing cookie
    }

    // Set App Context
    cookieConsent.setConsentData(newPurposesArray)

    // Set Cookie
    const IubendaCookie = prepareIubendaCookieObject(
      iubendaId,
      newPurposesArray
    )
    setCookie(`_iub_cs-${iubendaId}`, IubendaCookie, 365, true)
  }
}

export default updateVideoOptIn
