import VideoProviders from '../../types/video_providers'
import getVideoProviderFromURL from './get_video_provider_from_url'
import getYoutubeThumbnailFromURL from './youtube/get_youtube_thumbnail_from_url'

function getThumbnailFromURL(url: string) {
  if (!url) return null

  const videoProvider = getVideoProviderFromURL(url)

  if (videoProvider === VideoProviders.YOUTUBE) {
    return getYoutubeThumbnailFromURL(url)
  }

  return null
}

export default getThumbnailFromURL
