import Image from 'next/legacy/image'
import { FC } from 'react'
import { ClientsComponentContent } from '../../types/page_component_types'

import { Section, Slider, autoplaySettings, autoplayReverse } from '../'

export interface LogoCarousselCardProps {
  src: string
  alt: string
}

export const LogoCarousselCard: FC<LogoCarousselCardProps> = ({ alt, src }) => {
  return (
    <div className=" relative h-[100px] w-[100px] md:h-[180px] md:w-[180px]">
      <Image src={src} alt={alt || ''} layout="fill" objectFit="contain" />
    </div>
  )
}

/* https://www.figma.com/file/dlNJahOgzHG8DrFEL3srnt/Design-System?node-id=274%3A4214 */
export const ClientsComponent: FC<{
  content: ClientsComponentContent
}> = ({ content }) => {
  if (content.logos && content.logos.length < 6) {
    return (
      <Section fullWidth>
        <div className="flex flex-wrap justify-around gap-2 md:justify-evenly md:gap-0">
          {content.logos?.map((card, i) => (
            <div key={i}>
              {card.image?.src && (
                <LogoCarousselCard
                  src={card?.image?.src || ''}
                  alt={card?.image?.alt || ''}
                />
              )}
            </div>
          ))}
        </div>
      </Section>
    )
  } else if (content.logos && content.logos.length < 33) {
    return (
      <Section fullWidth>
        <div className="threeCard fiveCard">
          <Slider settings={autoplaySettings}>
            {content.logos?.map((card, i) => {
              return (
                <div className="px-2 md:px-3" key={i}>
                  {card.image?.src && (
                    <LogoCarousselCard
                      src={card?.image?.src || ''}
                      alt={card?.image?.alt || ''}
                    />
                  )}
                </div>
              )
            })}
          </Slider>
        </div>
      </Section>
    )
  } else {
    const firstLineLogoCount = Math.floor(content.logos?.length / 2)
    return (
      <Section fullWidth className="firstLogoSlider">
        <Slider settings={autoplaySettings}>
          {content.logos?.slice(0, firstLineLogoCount).map((card, i) => {
            return (
              <div className=" px-2 py-2 md:py-6 md:px-3" key={i}>
                {card.image?.src && (
                  <LogoCarousselCard
                    src={card?.image?.src || ''}
                    alt={card?.image?.alt || ''}
                  />
                )}
              </div>
            )
          })}
        </Slider>
        <Slider settings={autoplayReverse}>
          {content.logos?.slice(firstLineLogoCount).map((card, i) => {
            return (
              <div className="px-2 py-2 md:px-3 md:py-3" key={i}>
                {card.image?.src && (
                  <LogoCarousselCard
                    src={card?.image?.src || ''}
                    alt={card?.image?.alt || ''}
                  />
                )}
              </div>
            )
          })}
        </Slider>
      </Section>
    )
  }
}
