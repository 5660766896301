import { FC } from 'react'
import Image from 'next/legacy/image'

import { EventHeaderComponentContent } from '../../types/page_component_types'
import { ButtonPrimary, Section, MarkdownComponent } from '../'
import { FormatDateTimePeriod } from '../Time'
import classNames from 'classnames'

/*
 * https://www.figma.com/file/dlNJahOgzHG8DrFEL3srnt/Design-System?node-id=380%3A19595
 * version without slider
 */
export const EventHeaderComponent: FC<{
  content: EventHeaderComponentContent
}> = ({ content }) => {
  const now = Date.now()

  const data = content?.eventItem

  /**not final definition of color!!! have to refactor */
  const color =
    new Date(data?.time?.endtime || '').getTime() < now
      ? 'past'
      : new Date(data?.time?.starttime || '').getTime()
        ? 'next'
        : 'later'

  return (
    <Section className="flex flex-col items-center md:flex-row" darkBackground>
      <article className="w-full md:w-2/5">
        <h3
          className={classNames(
            color === 'past'
              ? 'bg-blue100 text-white'
              : 'textGradient orangeRed'
          )}
        >
          {data?.title}
        </h3>
        <div
          className={classNames(
            'mt-3 w-6 p-0.5',
            color === 'past' ? 'bg-white' : 'orangeRed'
          )}
        ></div>

        <div className="pt-8 text-white">
          {data?.time?.starttime && data?.time?.endtime && (
            <FormatDateTimePeriod
              startDate={new Date(data?.time?.starttime || '')}
              endDate={new Date(data?.time?.endtime || '')}
            />
          )}
        </div>
        <MarkdownComponent
          className="pb-16 text-white"
          markdown={data?.location?.address || ''}
        />
        {color !== 'past' && content?.button?.label && (
          <ButtonPrimary
            label={content?.button?.label}
            link={content?.button?.link || content?.eventItem?.slug}
          />
        )}
      </article>
      <figure className="clipImage relative order-first h-full w-full md:order-last md:w-3/5">
        {data.image?.src && (
          <Image
            className="clipImage"
            src={data.image?.src}
            alt={data.image?.alt || ''}
            width={750}
            height={558}
            objectFit="cover"
          />
        )}
        {color === 'past' && (
          <>
            <span className="absolute inset-0 h-full w-full bg-blue100 mix-blend-color"></span>
            <figcaption className="absolute top-1/6 -right-10 hidden h-5 w-36 rotate-[55deg] bg-red70 text-center text-sm uppercase tracking-wide text-white shadow-dropShadowRibbon md:block">
              ended
            </figcaption>
          </>
        )}
      </figure>
    </Section>
  )
}
