import React, { FC, useRef } from 'react'
import { VentureCard as TVentureCard } from '../../types/page_component_types'
import {
  ApiUrlOptions,
  usePaginatedCardsData
} from '../../utils/hooks/use_paginated_cards_data'
import classNames from 'classnames'
import { Section, VentureCard } from '../'
import { VenturesCollectionContent } from '../../types/page_component_types'

import ReactPaginate from 'react-paginate'
import { useRouter } from 'next/compat/router'
import { Locale } from '../../types/shared_types'

export const VenturesCollectionComponent: FC<{
  content: VenturesCollectionContent
}> = ({ content }) => {
  const topRef = useRef<HTMLElement>(null)
  const locale = (useRouter()?.locale || 'en') as Locale

  const urlOptions: ApiUrlOptions = {
    path: '/api/venture-cards',
    currentPageQueryStringKey: 'ventures'
  }

  const {
    currentPageCardsData: currentPageVentureCardsData,
    currentPageNumber,
    isLoading,
    pageCount,
    handleNewPage
  } = usePaginatedCardsData<TVentureCard>(
    content.cards,
    content.cardsPerPage,
    content.numTotalCards,
    urlOptions,
    topRef,
    locale
  )

  return (
    <Section ref={topRef}>
      <div className="relative mb-6 flex flex-wrap justify-center gap-6 py-5 sm:mb-8">
        <div
          className={classNames(
            isLoading
              ? 'absolute top-0 left-0 h-full w-full bg-black/7  transition-colors duration-75'
              : 'hidden'
          )}
        />
        {currentPageVentureCardsData?.map((ventureCardData, i) => {
          return (
            <VentureCard
              key={i}
              name={ventureCardData.venture_id || ''}
              shortDescription={ventureCardData.shortDescription || ''}
              slug={ventureCardData.slug || ''}
              src={ventureCardData.logo?.src || ''}
              alt={ventureCardData.logo?.alt}
            />
          )
        })}
      </div>
      <ReactPaginate
        pageCount={pageCount}
        marginPagesDisplayed={1}
        pageRangeDisplayed={3}
        onPageChange={handleNewPage}
        forcePage={currentPageNumber - 1}
        disableInitialCallback={true}
        previousLabel="« PREV"
        nextLabel="NEXT »"
        containerClassName="flex text-center h-6 w-full justify-between max-w-[25rem] mx-auto py-9 sm:py-[5.625rem]"
        pageClassName="w-8"
        pageLinkClassName="font-normal text-base w-full block leading-6"
        breakClassName="w-8"
        breakLinkClassName="w-full block text-base leading-6"
        previousClassName="w-20 flex-shrink-0"
        previousLinkClassName="w-full text-base block leading-6"
        nextClassName="w-20 flex-shrink-0"
        nextLinkClassName="w-full text-base block leading-6"
        activeLinkClassName="!font-bold"
      />
    </Section>
  )
}
