import React, { FC } from 'react'
import { VideoTextBannerComponentContent } from '../../types/page_component_types'
import {
  Section,
  MarkdownComponent,
  ButtonPrimary,
  ButtonSecondary,
  ButtonTertiary
} from '../'
import classNames from 'classnames'
import { VideoComponent } from './VideoComponent'
import { AvatarsComponent } from './AvatarsComponents'

export const VideoTextBannerComponent: FC<{
  content: VideoTextBannerComponentContent
  isPreview: boolean
}> = ({ content, isPreview }) => {
  // the cms cannot have an optional button without link and label also being optinal.
  // this makes sure we don't render partial buttons
  const linkbutton = content.linkbutton
  const hasValidButton = linkbutton?.label && linkbutton?.link

  const avatars = content.avatars
  const hasValidAvatars =
    avatars?.text && avatars?.image1 && avatars?.image2 && avatars?.image3

  let BannerButton
  if (hasValidButton) {
    switch (linkbutton.button_type) {
      case 'primary':
        BannerButton = (
          <ButtonPrimary label={linkbutton.label} link={linkbutton.link} />
        )
        break
      case 'secondary':
        BannerButton = (
          <ButtonSecondary label={linkbutton.label} link={linkbutton.link} />
        )
        break
      case 'tertiary':
        BannerButton = (
          <ButtonTertiary label={linkbutton.label} link={linkbutton.link} />
        )
        break
      default:
        BannerButton = (
          <ButtonPrimary label={linkbutton.label} link={linkbutton.link} />
        )
    }
  }

  return (
    <Section
      className={classNames(
        'flex items-center md:gap-x-16',
        content.textFirst
          ? 'flex-col md:flex-row'
          : 'flex-col-reverse md:flex-row-reverse'
      )}
      type={content.type}
      darkBackground={content.darkBackground}
      textFirst={content.textFirst}
    >
      <div className="w-full md:w-2/5">
        <MarkdownComponent markdown={content.richText || ''} />

        {hasValidAvatars && (
          <>
            <div className="py-3 md:py-5"></div>
            <AvatarsComponent avatars={avatars} />
          </>
        )}

        {hasValidButton && (
          <>
            <div className="py-3 md:py-5"></div>
            {BannerButton}
          </>
        )}
      </div>

      <figure
        className={classNames(
          'relative h-full w-full py-5 md:mb-0 md:w-3/5 md:py-10'
        )}
      >
        <VideoComponent
          content={{
            type: 'video',
            video: content.video
          }}
          isPreview={isPreview}
          noSection
          darkBackground={content.darkBackground}
        />
      </figure>
    </Section>
  )
}
