import classNames from 'classnames'
import React, { FC } from 'react'

interface TextInputProps {
  value: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  placeholder: string
  name: string
  type: string
  disabled?: boolean
  state?: string
  hasError?: boolean
}

export const TextInput: FC<TextInputProps> = ({
  onChange,
  value,
  placeholder,
  name,
  type,
  disabled,
  state,
  hasError
}) => {
  return (
    <div className="relative w-full">
      <input
        id={name}
        onChange={onChange}
        value={value}
        name={name}
        type={type}
        disabled={disabled}
        placeholder=" "
        className={classNames(
          state === 'error' && hasError
            ? 'border-red70'
            : 'border-blue100 focus:border-turquoise50 active:border-turquoise50',
          'border-gray-200 peer mb-8 mt-0 block w-full border-0 border-b-2 px-0.5 outline-none hover:border-turquoise50 focus:border-black focus:ring-0 md:mb-12'
        )}
      />
      <label
        htmlFor={name}
        className="absolute bottom-10 left-0.5 z-10 origin-[0] -translate-y-6 scale-[0.875] transform text-sm text-blue70 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-6 peer-focus:scale-[0.875] md:bottom-14"
      >
        {placeholder}*
      </label>
    </div>
  )
}
