import React, { FC } from 'react'
import { MembersCollectionContent } from '../../types/page_component_types'
import { MemberCard } from '../MemberCard'
import { Section } from '../Section'

/* does not exist in Figma design */
export const MembersCollectionComponent: FC<{
  content: MembersCollectionContent
}> = ({ content }) => {
  return (
    <Section className="mx-auto flex flex-wrap justify-center gap-6 md:w-[792px] md:justify-start lg:w-full">
      {content.cards?.map((card, i) => (
        <MemberCard
          key={i}
          membername={card.membername || ''}
          memberprofession={card.memberprofession || ''}
          memberinfo={card.memberinfo || ''}
          memberquote={card.memberquote || ''}
          src={card.memberphoto?.src || ''}
          alt={card.memberphoto?.alt || ''}
        />
      ))}
    </Section>
  )
}
