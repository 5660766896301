import classNames from 'classnames'
import React, { ButtonHTMLAttributes, FC } from 'react'
import { ArrowRight } from './Icons'
import Link from 'next/link'

interface ButtonBaseProps {
  label: string
  disabled?: boolean
  link?: string
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type']
  [prop: string]: any
}

export const ButtonPrimary: FC<
  ButtonBaseProps & { onClick?: () => void; className?: string }
> = ({ label, disabled, onClick, className, link, type, ...props }) => {
  const primaryButtonStyles = classNames(
    'orangeRed hover:dropShadowSmall disabled:hover:dropShadowNone rounded-sm px-8 py-4 uppercase text-white focus-visible:border-2 focus-visible:border-red30 focus-visible:py-[calc(1rem_-_2px)] focus-visible:px-[calc(2rem_-_2px)] focus-visible:outline-none active:bg-red70 active:bg-none disabled:bg-blue50 disabled:bg-none',
    className
  )

  if (link) {
    return (
      (<Link
        href={link}
        className={classNames(
          'inline-block text-button',
          primaryButtonStyles
        )}
        {...props}>

        {label}

      </Link>)
    );
  }

  return (
    <button
      type={type || 'button'}
      className={primaryButtonStyles}
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      {label}
    </button>
  )
}

export const ButtonSecondary: FC<ButtonBaseProps> = ({
  label,
  disabled,
  link,
  type,
  ...props
}) => {
  const secondaryButtonStyles =
    'hover:dropShadowSmall active:yellowPink disabled:hover:dropShadowNone rounded-sm border-2 border-red70 bg-white px-8 py-4 uppercase text-red70 disabled:border-blue50 disabled:text-blue50'

  if (link) {
    return (
      (<Link
        href={link}
        {...props}
        className={classNames(
          'inline-block text-button',
          secondaryButtonStyles
        )}>

        {label}

      </Link>)
    );
  }

  return (
    <button
      type={type || 'button'}
      disabled={disabled}
      className={secondaryButtonStyles}
      {...props}
    >
      {label}
    </button>
  )
}

 export const ButtonContent: FC<{
   label: string
   darkBg?: true | false
 }> = ({ label, darkBg }) => {
   return (
     <div
       className={classNames(
         'group inline-flex items-center pr-4 uppercase tracking-[.125rem] decoration-2 underline-offset-8 text-button disabled:pointer-events-none disabled:text-blue50 group-hover:underline ',
         darkBg
           ? 'active:orangeRed  active:textGradient fill-white text-white active:text-red70'
           : 'text-blue100 active:text-red70 active:underline'
       )}
     >
       {label}
       <span
         className={classNames(
           'ml-4 w-[1.5625rem] cursor-pointer group-disabled:pointer-events-none group-disabled:fill-blue50 md:w-[1.6875rem]',
           darkBg
             ? 'group-active:orangeRed group-active:textGradient fill-white group-active:fill-red70'
             : ' fill-blue100 group-active:fill-red70'
         )}
       >
         <ArrowRight />
       </span>
     </div>
   )
 }

 export const ButtonTertiary: FC<
   ButtonBaseProps & {
     className?: string
     onClick?: () => void
     darkBg?: true | false
   }
 > = ({
   label,
   link,
   disabled,
   onClick,
   darkBg,
   className,
   type,
   ...props
 }) => {
   const classes = classNames(
     'group tracking-[.125rem] inline-flex text-button items-center pr-4 uppercase decoration-2 underline-offset-8 hover:underline disabled:text-blue50 disabled:pointer-events-none ',
     darkBg
       ? 'active:orangeRed  active:text-red70 active:textGradient fill-white text-white'
       : 'text-blue100 active:text-red70 active:underline',
     className
   )

   return link ? (
     <Link
       href={link}
       passHref
       className={classes}
       {...props}
       aria-label={`Go to ${link}`}>

       <ButtonContent label={label} darkBg={darkBg} />

     </Link>
   ) : (
     <button
       type={type || 'button'}
       onClick={!disabled ? onClick : undefined}
       disabled={disabled}
       className={classes}
       {...props}
     >
       <ButtonContent label={label} darkBg={darkBg} />
     </button>
   );
 }
