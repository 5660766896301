import { FC } from 'react'
import Link from 'next/link'
import {
  RecommendedDocumentsProps,
  PageConfig
} from '../types/page_component_types'
import Image from 'next/legacy/image'
import { Section, MarkdownComponent } from './'

export const RecommendedDocuments: FC<{
  recommendations: RecommendedDocumentsProps[]
  config: PageConfig['recommendedConfig']
}> = ({ recommendations, config }) => {
  const fallbackImageMap: any = {
    jobs: config.careerImage,
    info: config.blogImage,
    blog: config.blogImage,
    services: config.blogImage,
    events: config.eventsImage,
    ventures: config.ventureImage
  }

  const getFallbackImage = (collection: any) => {
    return (
      fallbackImageMap[collection] ||
      '/website-image-re_think-circular-economy.jpg'
    )
  }

  return (
    <Section>
      <MarkdownComponent markdown={config?.headline || ''} />
      <ul className="mx-auto grid grid-cols-1 content-center items-start gap-6 py-20 md:w-full md:grid-cols-3  md:gap-12 md:py-14">
        {recommendations.map(item => (
          <li key={item.title || item?.venture_id} className="group">
            <Link href={item.slug || ''}>
              <Image
                src={
                  item?.image?.src ||
                  item?.meta?.image ||
                  getFallbackImage(item.collection)
                }
                alt={item?.image?.alt || ''}
                width={360}
                height={240}
              />
              <p className="my-4 uppercase text-blue70">{item.collection}</p>
              <h4 className="mb-4 group-hover:text-turquoise70">
                {item.title || item?.venture_id}
              </h4>
              <p>{item.meta.description}</p>
            </Link>
          </li>
        ))}
      </ul>
    </Section>
  )
}
