import classNames from 'classnames'
import React, { FC } from 'react'
import { BlockComponentContent } from '../../types/page_component_types'
import { Section, MarkdownComponent } from '../'

/* https://www.figma.com/file/dlNJahOgzHG8DrFEL3srnt/Design-System?node-id=1099%3A10964 */
export const BlockComponent: FC<{ content: BlockComponentContent }> = ({
  content
}) => (
  <Section
    type={content.type}
    className={classNames(content.noBottomPad && '-mb-5 md:-mb-10')}
  >
    <MarkdownComponent
      className={classNames(
        'max-w-full md:w-[46.5rem]',
        content.textCentered && 'mx-auto text-center'
      )}
      markdown={content.block || ''}
    />
  </Section>
)
