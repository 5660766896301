import VideoProviders from '../../types/video_providers'

function getVideoProviderFromURL(url: string) {
  if (isYoutubeUrl(url)) {
    return VideoProviders.YOUTUBE
  }
  return null
}

export default getVideoProviderFromURL

function isYoutubeUrl(url: string): boolean {
  const youtubeRegex =
    /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be|youtube-nocookie\.com)/
  return youtubeRegex.test(url)
}
