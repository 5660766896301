import React, { FC, SetStateAction } from 'react'
import Link from 'next/link'
import { HeaderItem } from '../types/page_component_types'
import LogoIcon from '../assets/logo_icon.svg'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { isExternalLink } from '../utils/isExternalLink'

export const Header: FC<{
  items: { menu: HeaderItem[] } | null
  open?: boolean
  setOpen?: React.Dispatch<SetStateAction<boolean>>
}> = ({ items, open, setOpen }) => {
  const router = useRouter()

  const highlightItems = (items?.menu && items?.menu.slice(0, 3)) || null
  const remainingItems = items?.menu.slice(3) || null
  const lastPathItem = (str: string) =>
    str.split('/').slice(-1).pop()?.replace(/\?.*/, '')

  const activeElement = lastPathItem(router.asPath)

  const showHamburger = items?.menu ? items?.menu.length <= 4 : false

  return (
    <header className="bg-blue100">
      <nav
        className={classNames(
          'relative mx-auto flex h-[5.5rem] max-w-[70.5rem] items-center justify-between px-6 text-white text-button md:h-36 lg:px-0'
        )}
      >
        <HeaderLogo />
        <MenuHighlights
          items={highlightItems}
          activeElement={activeElement}
          showHamburger={showHamburger}
        />
        <Hamburger
          open={open}
          setOpen={setOpen}
          showHamburger={showHamburger}
        />
        <LangToggle desktop />
      </nav>
      <MenuList
        items={{ highlightItems, remainingItems }}
        activeElement={activeElement}
        open={open}
      />
    </header>
  )
}

const HeaderLogo: FC = () => {
  return (
    (<Link
      href="/"
      className="svgWrapper z-50 inline-flex h-12 shrink-0 items-center">

      <div className="h-10 w-10 md:h-11 md:w-11">
        <LogoIcon />
      </div>
      <span className="ml-5 text-sm font-medium uppercase tracking-[6px] md:ml-6 md:text-base">
        n3xtcoder
      </span>

    </Link>)
  );
}

const MenuHighlights: FC<{
  items: HeaderItem[] | null
  activeElement?: string
  showHamburger: boolean
}> = ({ items, activeElement, showHamburger }) => {
  return (
    <ul
      className={classNames(
        'align-center top-auto hidden w-9/12 justify-end gap-x-10 text-center md:flex',
        !showHamburger && 'mr-7'
      )}
    >
      {items?.length &&
        items.map((link, i) => (
          <HeaderListItem key={i} activeElement={activeElement} link={link} />
        ))}
    </ul>
  )
}

const MenuList: FC<{
  items: {
    highlightItems: HeaderItem[] | null
    remainingItems: HeaderItem[] | null
  }
  open?: boolean
  activeElement?: string
}> = ({ items, activeElement, open }) => {
  const hasRemainingItems = !!items?.remainingItems?.length

  return (
    <div
      className={classNames(
        'align-center z-50 w-full bg-blue100 text-white transition-opacity ease-in',
        open
          ? 'fixed h-full md:absolute md:h-auto'
          : 'invisible absolute h-auto opacity-0'
      )}
    >
      <ul className="mx-auto flex h-full max-w-[70.5rem] flex-col gap-x-10 overflow-auto px-6 pb-12 text-center md:flex-row md:justify-end md:pb-6 lg:px-0">
        <LangToggle />
        <hr className="mx-auto my-16 w-32 border-t-2 md:hidden" />
        {!!items?.highlightItems?.length &&
          items?.highlightItems.map((link, i) => (
            <HeaderListItem
              key={i}
              activeElement={activeElement}
              link={link}
              mobileOnly
            />
          ))}
        {hasRemainingItems &&
          items?.remainingItems?.map((link, i) => (
            <HeaderListItem key={i} activeElement={activeElement} link={link} />
          ))}
      </ul>
    </div>
  )
}

const Hamburger: FC<{
  open?: boolean
  setOpen?: (value: boolean) => void
  showHamburger: boolean
}> = ({ open, setOpen, showHamburger }) => {
  if (open)
    return (
      <button
        className={classNames(
          'relative right-1 h-[24px] w-[24px] flex-shrink-0 p-1',
          showHamburger && 'md:hidden'
        )}
        onClick={() => setOpen && setOpen(!open)}
      >
        <span
          className={classNames(
            'absolute block h-0.5 w-full rotate-45 rounded-lg bg-turquoise70'
          )}
        ></span>
        <span
          className={classNames(
            'absolute block h-0.5 w-full -rotate-45 rounded-lg bg-turquoise70'
          )}
        ></span>
      </button>
    )
  return (
    <button
      className={classNames(
        'relative flex h-[24px] w-[24px] flex-shrink-0 flex-col justify-between p-1',
        showHamburger && 'md:hidden'
      )}
      onClick={() => setOpen && setOpen(!open)}
      aria-label="main-navigation-toggle"
    >
      <span
        className={classNames(
          'block h-0.5 w-full transform rounded-lg bg-white'
        )}
      ></span>
      <span
        className={classNames(
          'block h-0.5 w-full transform rounded-lg bg-white'
        )}
      ></span>
      <span
        className={classNames(
          'block h-0.5 w-full transform rounded-lg bg-white'
        )}
      ></span>
    </button>
  )
}

const HeaderListItem: FC<{
  activeElement?: string
  link: {
    label: string
    href: string
  }
  mobileOnly?: boolean
}> = ({ activeElement, link, mobileOnly }) => {
  return (
    (<li
      className={classNames(
        mobileOnly && 'md:hidden',
        'mb-16 font-medium uppercase md:mb-0'
      )}
    >
      <Link
        href={link?.href || '/'}
        target={isExternalLink(link?.href) ? '_blank' : '_self'}
        rel={isExternalLink(link?.href || '') ? `noreferrer` : ''}
        className="py-3 text-button hover:text-turquoise70">

        <span
          className={classNames(
            activeElement &&
              link?.href.includes(activeElement) &&
              'border-b border-turquoise70 pb-1 text-turquoise70'
          )}
        >
          {link?.label || '/'}
        </span>

      </Link>
    </li>)
  );
}

const LangToggle: FC<{ desktop?: boolean }> = ({ desktop }) => {
  const router = useRouter()

  const localesList = router.locales
    ?.map((locale, index) => {
      const isActive = locale === router.locale
      const isLastElem = router.locales?.length === index + 1

      const localeLink = (
        <Link
          href={router.asPath}
          locale={locale}
          key={locale}
          className="text-button hover:text-turquoise70 md:py-3">

          <span className={classNames(isActive && 'text-turquoise70')}>
            {locale}
          </span>

        </Link>
      )

      if (isLastElem) {
        return localeLink
      }

      return [
        localeLink,
        <div className="mx-1 md:py-3" key={index}>
          /
        </div>
      ]
    })
    .flat()

  return (
    <div
      className={classNames(
        'font-medium uppercase md:mb-0',
        desktop ? 'hidden md:ml-10 md:flex' : 'mx-auto flex md:hidden'
      )}
    >
      {localesList}
    </div>
  )
}
