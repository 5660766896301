const plugin = require('tailwindcss/plugin')

module.exports = {
  content: [
    './pages/**/*.{js,ts,jsx,tsx}',
    './components/**/*.{js,ts,jsx,tsx}'
  ],
  plugins: [
    require('@tailwindcss/forms'),
    plugin(function ({ addUtilities, theme }) {
      addUtilities({
        '.position-unset': {
          position: 'unset'
        }
      })
      const md = theme('screens.md', {})
      addUtilities({
        '.text-body': {
          fontSize: theme('fontSize.sm'),
          lineHeight: theme('lineHeight.lg'),
          [`@media (min-width: ${md})`]: {
            fontSize: theme('fontSize.base')
          }
        },
        '.text-body-lg': {
          fontSize: theme('fontSize.md'),
          lineHeight: theme('lineHeight.lg'),
          fontWeight: theme('fontWeight.light'),

          [`@media (min-width: ${md})`]: {
            fontSize: theme('fontSize.lg')
          }
        },
        '.text-small': {
          fontSize: theme('fontSize.xs'),
          lineHeight: theme('lineHeight.sm'),
          fontWeight: theme('fontWeight.light')
        },
        '.text-h1': {
          fontSize: theme('fontSize.3xl'),
          lineHeight: theme('lineHeight.sm'),
          fontWeight: theme('fontWeight.medium'),
          [`@media (min-width: ${md})`]: {
            fontSize: theme('fontSize.5xl')
          }
        },
        '.text-h2': {
          fontSize: theme('fontSize.xl'),
          lineHeight: theme('lineHeight.sm'),
          fontWeight: theme('fontWeight.medium'),
          [`@media (min-width: ${md})`]: {
            fontSize: theme('fontSize.4xl')
          }
        },
        '.text-h3': {
          fontSize: theme('fontSize.lg'),
          lineHeight: theme('lineHeight.sm'),
          fontWeight: theme('fontWeight.medium'),
          [`@media (min-width: ${md})`]: {
            fontSize: theme('fontSize.2xl')
          }
        },
        '.text-h4': {
          fontSize: theme('fontSize.md'),
          lineHeight: theme('lineHeight.base'),
          fontWeight: theme('fontWeight.medium'),
          [`@media (min-width: ${md})`]: {
            fontSize: theme('fontSize.lg')
          }
        },
        '.text-h5': {
          fontSize: theme('fontSize.base'),
          lineHeight: theme('lineHeight.base'),
          fontWeight: theme('fontWeight.light'),
          letterSpacing: theme('letterSpacing.wide'),
          textTransform: 'uppercase',
          [`@media (min-width: ${md})`]: {
            fontSize: theme('fontSize.md')
          }
        },

        '.text-button': {
          fontSize: theme('fontSize.xs'),
          lineHeight: theme('lineHeight.btn'),
          fontWeight: theme('fontWeight.medium'),
          textTransform: 'uppercase',
          letterSpacing: theme('letterSpacing.wide'),
          [`@media (min-width: ${md})`]: {
            fontSize: theme('fontSize.sm'),
            lineHeight: theme('lineHeight.sm')
          }
        }
      })
    })
  ],
  theme: {
    fontSize: {
      xs: '0.875rem',
      sm: '1rem',
      base: '1.125rem',
      md: '1.25rem',
      lg: '1.5rem',
      xl: '1.75rem',
      '2xl': '1.875rem',
      '3xl': '2rem',
      '4xl': '2.25rem',
      '5xl': '2.5rem'
    },
    lineHeight: {
      sm: '120%',
      base: '130%',
      lg: '140%'
    },
    extend: {
      inset: {
        '1/6': '15%',
        sliderArrows: '1.95rem',
        sliderArrowsMobile: '1.7rem',
        sliderArrowsDarkSection: '5.95rem',
        sliderArrowsMobileDarkSection: '3.7rem',
        sliderIndicatorsDarkSection: '5.7rem'
      },
      boxShadow: {
        dropShadowRibbon: '0px 4px 4px rgba(0, 0, 0, 0.25)'
      },
      lineClamp: {
        7: '7',
        8: '8',
        9: '9',
        10: '10'
      },
      spacing: {
        '7/10': '70%',
        unset: 'unset'
      },
      opacity: {
        7: '0.07'
      }
    },
    screens: {
      sm: '600px',
      md: '905px',
      lg: '1240px',
      xl: '1440px'
    },
    fontFamily: {
      sans: ['Rubik, sans-serif;']
    },
    colors: {
      blue100: '#000045',
      blue10: '#F6F6FA',
      white: '#FFFFFF',
      blue70: '#6E6EAA',
      blue50: '#7E7EB4',
      blue30: '#DFDFEF',
      red70: '#EA4E61',
      red50: '#F0939E',
      red30: '#FFD9D9',
      red100: '#FF0200',
      yellow70: '#FFA300',
      yellow50: '#FDC664',
      yellow30: '#FFEFD2',
      turquoise70: '#20BBC8',
      turquoise50: '#73CFD6',
      turquoise30: '#B9EBEA',
      transparent: 'transparent',
      black: '#000000'
    }
  }
}
