import classNames from 'classnames'
import React, { FC, useRef } from 'react'
import { useTranslations } from 'next-intl'
import {
  EventCard as TEventCard,
  PastEventsCollectionContent
} from '../../types/page_component_types'
import {
  ApiUrlOptions,
  usePaginatedCardsData
} from '../../utils/hooks/use_paginated_cards_data'
import { Section, EventCard } from '../'
import ReactPaginate from 'react-paginate'
import { useRouter } from 'next/compat/router'
import { Locale } from '../../types/shared_types'

export const EventsCollectionCardsComponent: FC<{
  content: PastEventsCollectionContent
}> = ({ content }) => {
  const t = useTranslations()
  const locale = (useRouter()?.locale || 'en') as Locale

  const topRef = useRef<HTMLElement>(null)
  const urlOptions: ApiUrlOptions = {
    path: '/api/event-cards',
    currentPageQueryStringKey: 'past-events-page',
    additionalQueryParams: { filter: 'past' }
  }

  const {
    currentPageCardsData: currentPageEventCardsData,
    currentPageNumber,
    isLoading,
    pageCount,
    handleNewPage
  } = usePaginatedCardsData<TEventCard>(
    content.cards,
    content.cardsPerPage,
    content.numTotalCards,
    urlOptions,
    topRef,
    locale
  )

  const pagination = (
    <ReactPaginate
      pageCount={pageCount}
      marginPagesDisplayed={1}
      pageRangeDisplayed={3}
      onPageChange={handleNewPage}
      forcePage={currentPageNumber - 1}
      disableInitialCallback={true}
      previousLabel={`« ${t('previous').toUpperCase()}`}
      nextLabel={`${t('next').toUpperCase()} »`}
      containerClassName="flex text-center h-6 w-full justify-between max-w-[25rem] mx-auto py-9 sm:py-[5.625rem]"
      pageClassName="w-8"
      pageLinkClassName="font-normal text-base w-full block leading-6"
      breakClassName="w-8"
      breakLinkClassName="w-full block text-base leading-6"
      previousClassName="w-20 flex-shrink-0"
      previousLinkClassName="w-full text-base block leading-6 whitespace-pre"
      nextClassName="w-20 flex-shrink-0"
      nextLinkClassName="w-full text-base block leading-6 whitespace-pre"
      activeLinkClassName="!font-bold"
      disabledClassName="opacity-30 pointer-events-none"
    />
  )

  return (
    <Section ref={topRef}>
      {pagination}
      <div className="relative mb-6 grid grid-cols-1 gap-5 sm:mb-8 md:grid-cols-2 lg:grid-cols-3">
        <div
          className={classNames(
            isLoading
              ? 'absolute top-0 left-0 h-full w-full bg-black/7  transition-colors duration-75'
              : 'hidden'
          )}
        />
        {currentPageEventCardsData?.map((eventCardData, index) => {
          return (
            <EventCard
              key={eventCardData.guid || index}
              title={eventCardData.title || ''}
              typeOfEvent={eventCardData.typeOfEvent || 'hackathon'}
              timeFrame={eventCardData.timeFrame || {}}
              slug={eventCardData.slug || ''}
            />
          )
        })}
      </div>
      {pagination}
    </Section>
  )
}
