import Image from 'next/legacy/image'
import Link from 'next/link'
import { FC } from 'react'
import { ButtonTertiary } from './Buttons'

export interface VentureCardProps {
  name?: string
  src: string
  alt?: string
  shortDescription?: string
  slug?: string
}

export const VentureCard: FC<VentureCardProps> = ({
  name,
  src,
  alt,
  shortDescription,
  slug
}) => {
  return (
    <Link href={slug || ''}>
      <article className="dropShadowSmall group flex h-[11.25rem] w-[360px] flex-col bg-blue10 px-2 py-4 sm:h-[35.5rem] md:px-4 md:py-8">
        <div className="flex h-full flex-col sm:mb-4">
          <h4 className="group-hover:text-turquoise70 sm:mx-auto sm:text-center md:min-h-[4.75rem]">
            {name}
          </h4>

          <figure className="relative order-first mb-6  h-[3.5rem] w-[3.5rem] sm:order-none sm:mx-auto sm:mb-6 sm:h-[6.25rem] sm:w-[6.25rem] md:mb-8">
            <Image
              src={src}
              alt={alt || ''}
              layout="fill"
              objectFit="contain"
            />
          </figure>

          <p className="mb-11 hidden font-light sm:visible sm:line-clamp-9">
            {shortDescription || ''}
          </p>
        </div>
      </article>
    </Link>
  )
}
