import React, { FC, useEffect, useState } from 'react'
import { NewsletterComponentContent } from '../../types/page_component_types'
import emailValidator from 'email-validator'
import MailchimpSubscribe, { EmailFormFields } from 'react-mailchimp-subscribe'
import classNames from 'classnames'
import { ButtonPrimary, TextInput, Section, MarkdownComponent } from '../'
import Letters from '../../assets/icon_letters.png'
import ErrorIcon from '../../assets/icon_error.svg'
import CheckIcon from '../../assets/icon_checked.svg'
import InfoIcon from '../../assets/icon_info.svg'
import Image from 'next/legacy/image'

interface Formdata {
  EMAIL?: string
  FNAME?: string
  LNAME?: string
  PRIVACY?: boolean
}

/* https://www.figma.com/file/dlNJahOgzHG8DrFEL3srnt/Design-System?node-id=274%3A2785 */
export const NewsletterComponent: FC<{
  content: NewsletterComponentContent
}> = ({ content }) => {
  return (
    <Section>
      <div className="mx-auto flex flex-col items-center md:w-[530px] lg:w-[760px]">
        <div className="relative w-[125px] md:w-[158px]">
          <Image src={Letters} alt="letters" />
        </div>
        <h4 className="my-8 text-center md:my-16 lg:px-7">{content.heading}</h4>
        <MailchimpFormContainer
          privacy_notice={content.richText}
          buttonText={content.button?.label || ''}
        />
      </div>
    </Section>
  )
}

const MailchimpFormContainer: FC<{
  buttonText: string
  privacy_notice?: string
}> = ({ buttonText, privacy_notice }) => {
  const u = process.env.NEXT_PUBLIC_MAILCHIMP_U
  const id = process.env.NEXT_PUBLIC_MAILCHIMP_AUDIENCE_ID

  const postUrl = `${process.env.NEXT_PUBLIC_MAILCHIMP_POST_URL}?u=${u}&id=${id}`

  return (
    <MailchimpSubscribe
      url={postUrl || ''}
      render={({ subscribe, status, message }) => (
        <CustomForm
          status={status}
          responseMessage={message}
          onValidated={(formData: Formdata) =>
            subscribe(formData as EmailFormFields)
          }
          buttonText={buttonText}
          privacy_notice={privacy_notice}
        />
      )}
    />
  )
}

interface MessageIconsProps {
  [state: string]: JSX.Element
}

const Message: FC<{ message: JSX.Element; type: string }> = ({
  message,
  type
}) => {
  const messageIcons: MessageIconsProps = {
    error: <ErrorIcon />,
    sending: <InfoIcon />,
    success: <CheckIcon />
  }
  return (
    <div
      className={classNames(
        'flex items-center gap-4',
        type === 'error' ? 'text-red70' : 'text-blue70'
      )}
    >
      <span className="svg-wrapper">{messageIcons[type]}</span>
      {message}
    </div>
  )
}

interface Message {
  jsxParagraph: JSX.Element
  type: 'sending' | 'error' | 'success'
  fields?: string[]
}

const CustomForm: FC<{
  buttonText: string
  status: 'sending' | 'error' | 'success' | null
  responseMessage: string | Error | null
  onValidated: (formData: Formdata) => void
  privacy_notice?: string
}> = ({ responseMessage, buttonText, status, onValidated, privacy_notice }) => {
  const [formData, setFormData] = useState<Formdata | null>(null)
  const [message, setMessage] = useState<Message | null>(null)
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    switch (status) {
      case 'error':
        setMessage({
          jsxParagraph: (
            <p
              dangerouslySetInnerHTML={{
                __html: (responseMessage as string | Error).toString()
              }}
            />
          ),
          type: 'error'
        })
        break
      case 'sending':
        setMessage({
          jsxParagraph: <p>Sending...</p>,
          type: 'sending'
        })
        break
      case 'success':
        setMessage({
          jsxParagraph: (
            <p
              dangerouslySetInnerHTML={{
                __html: (responseMessage as string | Error).toString()
              }}
            />
          ),
          type: 'success'
        })
        setFormData(null)
        break
      default:
        setMessage(null)
        break
    }
  }, [status, responseMessage])

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newData = {
      [event.target.name]:
        event.target.name === 'PRIVACY'
          ? event.target.checked
          : event.target.value
    }
    setFormData({
      ...formData,
      ...newData
    })
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    interface Names {
      FNAME?: string
      LNAME?: string
      EMAIL?: string
      PRIVACY?: string
    }

    const displayNames = {
      FNAME: 'First name',
      LNAME: 'Last name',
      EMAIL: 'Email address',
      PRIVACY: 'Privacy policy'
    }
    const FIELDS = Object.keys(displayNames)

    const missingFields = FIELDS.filter(
      field => !Object.keys(formData || []).includes(field)
    )
    const listFormatter = new Intl.ListFormat('en', {
      style: 'long',
      type: 'conjunction'
    })

    if (missingFields && missingFields.length) {
      setMessage({
        jsxParagraph: (
          <p>
            {listFormatter.format(
              missingFields.map(item => displayNames[item as keyof Names])
            )}{' '}
            are required fields
          </p>
        ),
        type: 'error',
        fields: missingFields
      })
      setSubmitting(false)
      return
    }

    if (formData?.EMAIL && !emailValidator.validate(formData?.EMAIL)) {
      setMessage({
        jsxParagraph: <p>Please add a valid email address</p>,
        type: 'error',
        fields: ['EMAIL']
      })
      setSubmitting(false)
      return
    }
    onValidated({
      EMAIL: formData?.EMAIL,
      FNAME: formData?.FNAME,
      LNAME: formData?.LNAME
    })
    setSubmitting(false)
  }

  const showMessage = (
    <div className="mb-2.5 min-h-[1.5rem] text-center md:mb-7">
      {message && (
        <Message message={message.jsxParagraph} type={message.type} />
      )}
    </div>
  )

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="flex w-full flex-col items-center"
      >
        <div className="flex w-full flex-col items-center sm:w-[450px]">
          <TextInput
            onChange={handleOnChange}
            value={formData?.FNAME || ''}
            placeholder="Name"
            name="FNAME"
            type="text"
            state={message?.type}
            hasError={message?.fields?.includes('FNAME')}
          />
          <TextInput
            onChange={handleOnChange}
            value={formData?.LNAME || ''}
            placeholder="Surname"
            name="LNAME"
            type="text"
            state={message?.type}
            hasError={message?.fields?.includes('LNAME')}
          />
          <TextInput
            onChange={handleOnChange}
            value={formData?.EMAIL || ''}
            placeholder="E-Mail"
            name="EMAIL"
            type="text"
            state={message?.type}
            hasError={message?.fields?.includes('EMAIL')}
          />
        </div>
        {privacy_notice && (
          <label htmlFor="privacy" className="flex items-center gap-2">
            <input
              type="checkbox"
              id="privacy"
              name="PRIVACY"
              onChange={handleOnChange}
              className="border-gray-300 rounded text-blue100 shadow-sm focus:border-blue100 focus:ring focus:ring-blue100 focus:ring-opacity-0 focus:ring-offset-0"
              checked={formData?.PRIVACY || false}
            />
            <MarkdownComponent
              className={classNames(
                message?.type === 'error' &&
                  message?.fields?.includes('PRIVACY') &&
                  'text-red70'
              )}
              markdown={privacy_notice || ''}
            />
          </label>
        )}
        <ButtonPrimary
          className="my-10"
          label={buttonText}
          type="submit"
          disabled={submitting}
        />
      </form>
      {showMessage}
    </>
  )
}
