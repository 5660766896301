import React, { ReactElement } from 'react'
import {
  AccordionComponent,
  BlockComponent,
  BlogsCollectionComponent,
  ButtonComponent,
  ClientsComponent,
  EventAgendaComponent,
  EventBannerComponent,
  EventCardsComponent,
  EventHeaderComponent,
  FullWidthImageComponent,
  GalleryComponent,
  GuestsComponent,
  ImageTextBannerComponent,
  InteractiveCardsComponent,
  JobsCollectionComponent,
  HTMLComponent,
  LineComponent,
  LivestreamComponent,
  MembersCollectionComponent,
  ModalButtonSliderComponent,
  NewsletterComponent,
  PortfolioComponent,
  QuoteComponent,
  ServicesCardsComponent,
  SpacerComponent,
  TestimonialsComponent,
  ThreeColumnsComponent,
  ThreeColumnsExtendedComponent,
  FourColumnsComponent,
  TimerComponent,
  TwoColumnsComponent,
  TwoColumnsExtendedComponent,
  VenturesComponent,
  VenturesCollectionComponent,
  VideoComponent,
  WhenwhereComponent,
  VideoTextBannerComponent,
  ServicesCollectionCardsComponent,
  EventsCollectionCardsComponent
} from '../components/page_components'
import { TonnenheldBlockComponent } from '../components/page_components/TonnenheldBlockComponent'
import { TonnenheldFullWidthImageComponent } from '../components/page_components/TonnenheldFullWidthImageComponent'
import { TonnenheldImageTextBannerComponent } from '../components/page_components/TonnenheldImageTextBannerComponent'
import { TonnenheldSubmitButtonComponent } from '../components/page_components/TonnenheldSubmitButtonComponent'
import { TonnenheldThreeColumnsComponent } from '../components/page_components/TonnenheldThreeColumnsComponent'
import { Section } from '../components/Section'

import {
  AccordionComponentContent,
  BlockComponentContent,
  BlogsCollectionContent,
  ButtonComponentContent,
  ClientsComponentContent,
  EventAgendaComponentContent,
  EventBannerComponentContent,
  EventCardsComponentContent,
  EventHeaderComponentContent,
  FullWidthImageComponentContent,
  GalleryComponentContent,
  GuestsComponentContent,
  HTMLComponentContent,
  ImageTextBannerComponentContent,
  InteractiveCardsComponentContent,
  JobsCollectionContent,
  LineComponentContent,
  LivestreamComponentContent,
  MembersCollectionContent,
  ModalButtonSliderComponentContent,
  NewsletterComponentContent,
  PageComponentProps,
  PastEventsCollectionContent,
  PortfolioComponentContent,
  QuoteComponentContent,
  ServicesCardsComponentContent,
  ServicesCollectionContent,
  SpacerComponentContent,
  TestimonialsComponentContent,
  ThreeColumnsComponentContent,
  ThreeColumnsExtendedComponentContent,
  TimerComponentContent,
  TonnenheldSubmitButtonContent,
  TwoColumnsComponentContent,
  TwoColumnsExtendedComponentContent,
  VenturesComponentContent,
  VenturesCollectionContent,
  VideoComponentContent,
  WhenwhereComponentContent,
  TonnenheldImageTextBannerComponentContent,
  VideoTextBannerComponentContent,
  FourColumnsComponentContent
} from '../types/page_component_types'

export const getPageComponents = ({
  content,
  isPreview
}: {
  content: PageComponentProps
  isPreview: boolean
}) => {
  let component: ReactElement | null = null

  switch (content?.type) {
    case 'block':
      component = <BlockComponent content={content as BlockComponentContent} />
      break
    case 'html':
      component = <HTMLComponent content={content as HTMLComponentContent} />
      break
    case 'services_cards':
      component = (
        <ServicesCardsComponent
          content={content as ServicesCardsComponentContent}
        />
      )
      break
    case 'linkbutton':
      component = (
        <ButtonComponent content={content as ButtonComponentContent} />
      )
      break
    case 'typeform':
      component = (
        <ButtonComponent content={content as ButtonComponentContent} />
      )
      break
    case 'linkCTA':
      component = (
        <ButtonComponent content={content as ButtonComponentContent} />
      )
      break
    case 'twocolumns':
      component = (
        <TwoColumnsComponent
          content={content as TwoColumnsComponentContent}
          isPreview={isPreview}
        />
      )
      break
    case 'twocolumns_extended':
      component = (
        <TwoColumnsExtendedComponent
          content={content as TwoColumnsExtendedComponentContent}
          isPreview={isPreview}
        />
      )
      break
    case 'threecolumns':
      component = (
        <ThreeColumnsComponent
          content={content as ThreeColumnsComponentContent}
          isPreview={isPreview}
        />
      )
      break
    case 'threecolumns_extended':
      component = (
        <ThreeColumnsExtendedComponent
          content={content as ThreeColumnsExtendedComponentContent}
          isPreview={isPreview}
        />
      )
      break
    case 'fourcolumns':
      component = (
        <FourColumnsComponent
          content={content as FourColumnsComponentContent}
        />
      )
      break
    case 'video':
      component = (
        <VideoComponent
          content={content as VideoComponentContent}
          isPreview={isPreview}
        />
      )
      break
    case 'line':
      component = <LineComponent content={content as LineComponentContent} />
      break

    case 'spacer':
      component = (
        <SpacerComponent content={content as SpacerComponentContent} />
      )
      break
    case 'gallery':
      component = (
        <GalleryComponent content={content as GalleryComponentContent} />
      )
      break
    case 'livestream':
      component = (
        <LivestreamComponent content={content as LivestreamComponentContent} />
      )
      break
    case 'quote':
      component = <QuoteComponent content={content as QuoteComponentContent} />
      break
    case 'ventures':
      component = (
        <VenturesComponent content={content as VenturesComponentContent} />
      )
      break
    case 'modalButtonSlider':
      component = (
        <ModalButtonSliderComponent
          content={content as ModalButtonSliderComponentContent}
        />
      )
      break
    case 'whenwhere':
      component = (
        <WhenwhereComponent content={content as WhenwhereComponentContent} />
      )
      break
    case 'imageTextBanner':
      component = (
        <ImageTextBannerComponent
          content={content as ImageTextBannerComponentContent}
        />
      )
      break
    case 'interactiveCards':
      component = (
        <InteractiveCardsComponent
          content={content as InteractiveCardsComponentContent}
        />
      )
      break
    case 'portfolios':
      component = (
        <PortfolioComponent content={content as PortfolioComponentContent} />
      )
      break
    case 'fullWidthImage':
      component = (
        <FullWidthImageComponent
          content={content as FullWidthImageComponentContent}
        />
      )
      break
    case 'newsletter':
      component = (
        <NewsletterComponent content={content as NewsletterComponentContent} />
      )
      break
    case 'eventCards':
      component = (
        <EventCardsComponent content={content as EventCardsComponentContent} />
      )
      break
    case 'eventBanner':
      component = (
        <EventBannerComponent
          content={content as EventBannerComponentContent}
        />
      )
      break
    case 'eventHeader':
      component = (
        <EventHeaderComponent
          content={content as EventHeaderComponentContent}
        />
      )
      break
    case 'guestBios':
      component = (
        <GuestsComponent content={content as GuestsComponentContent} />
      )
      break
    case 'eventAgenda':
      component = (
        <EventAgendaComponent
          content={content as EventAgendaComponentContent}
        />
      )
      break
    case 'accordion':
      component = (
        <AccordionComponent content={content as AccordionComponentContent} />
      )
      break
    case 'timer':
      component = <TimerComponent content={content as TimerComponentContent} />
      break
    case 'testimonials':
      component = (
        <TestimonialsComponent
          content={content as TestimonialsComponentContent}
        />
      )
      break
    case 'tonnenheld_imageTextBanner':
      component = (
        <TonnenheldImageTextBannerComponent
          content={content as TonnenheldImageTextBannerComponentContent}
        />
      )
      break
    case 'tonnenheld_block':
      component = (
        <TonnenheldBlockComponent content={content as BlockComponentContent} />
      )
      break
    case 'tonnenheld_threecolumns':
      component = (
        <TonnenheldThreeColumnsComponent
          content={content as ThreeColumnsComponentContent}
        />
      )
      break
    case 'tonnenheld_submitbutton':
      component = (
        <TonnenheldSubmitButtonComponent
          content={content as TonnenheldSubmitButtonContent}
        />
      )
      break
    case 'tonnenheld_fullWidthImage':
      component = (
        <TonnenheldFullWidthImageComponent
          content={content as FullWidthImageComponentContent}
        />
      )
      break
    case 'clients':
      component = (
        <ClientsComponent content={content as ClientsComponentContent} />
      )
      break
    case 'servicesCollection':
      component = (
        <ServicesCollectionCardsComponent
          content={content as ServicesCollectionContent}
        />
      )
      break
    case 'pastEventsCollection':
      component = (
        <EventsCollectionCardsComponent
          content={content as PastEventsCollectionContent}
        />
      )
      break
    case 'blogsCollection':
      component = (
        <BlogsCollectionComponent content={content as BlogsCollectionContent} />
      )
      break
    case 'jobsCollection':
      component = (
        <JobsCollectionComponent content={content as JobsCollectionContent} />
      )
      break
    case 'venturesCollection':
      component = (
        <VenturesCollectionComponent
          content={content as VenturesCollectionContent}
        />
      )
      break
    case 'membersCollection':
      component = (
        <MembersCollectionComponent
          content={content as MembersCollectionContent}
        />
      )
      break
    case 'videoTextBanner':
      component = (
        <VideoTextBannerComponent
          content={content as VideoTextBannerComponentContent}
          isPreview={isPreview}
        />
      )
      break
    default:
      component = <Section />
      break
  }
  return component
}
