import classNames from 'classnames'
import { FC } from 'react'
import ReactMarkdown from 'react-markdown'
import { ThreeColumnsComponentContent } from '../../types/page_component_types'
import { Section } from '../Section'

/*
 * https://www.figma.com/file/dlNJahOgzHG8DrFEL3srnt/Design-System?node-id=274%3A4863
 * https://www.figma.com/file/dlNJahOgzHG8DrFEL3srnt/Design-System?node-id=274%3A5037
 * to make ThreeColumnsComponent compatible with old CMS: out of markdown created jsx/html needs to be styled (f.e. images above columns' headings as defined in figma)
 */
export const TonnenheldThreeColumnsComponent: FC<{
  content: ThreeColumnsComponentContent
}> = ({ content }) => {
  return (
    <Section>
      <article className="grid-cols-3 gap-6 md:grid">
        <div className="col-1 mb-8 md:pr-6">
          <ReactMarkdown
            className={classNames(
              content.textCentered && 'text-center',
              'contentStyles tonnenheldThreeColumnContentStyles column-element'
            )}
          >
            {content?.col1of3 || ''}
          </ReactMarkdown>
        </div>
        <div className="col-1 mb-8 md:pr-6">
          <ReactMarkdown
            className={classNames(
              content.textCentered && 'text-center',
              'contentStyles tonnenheldThreeColumnContentStyles column-element'
            )}
          >
            {content?.col2of3 || ''}
          </ReactMarkdown>
        </div>
        <div className="col-1 md:pr-6">
          <ReactMarkdown
            className={classNames(
              content.textCentered && 'text-center',
              'contentStyles tonnenheldThreeColumnContentStyles column-element'
            )}
          >
            {content?.col3of3 || ''}
          </ReactMarkdown>
        </div>
      </article>
    </Section>
  )
}
