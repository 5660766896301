import React, { FC } from 'react'
import classNames from 'classnames'
import tailwindConfig from '../tailwind.config'
//for storybook
//import { Swiper, SwiperSlide, SwiperProps } from 'swiper/react/swiper-react'
import { Swiper, SwiperSlide, SwiperProps } from 'swiper/react'
import { Navigation, Pagination, A11y, Autoplay } from 'swiper/modules'

interface Props {
  settings?: SwiperProps
  children?: any
  className?: string
  darkBackground?: boolean
  shadow?: boolean
}

const breakPoints = tailwindConfig.theme.screens

const defaultSettings: SwiperProps = {
  modules: [Navigation, Pagination, Autoplay, A11y],
  speed: 500,
  spaceBetween: 24,
  navigation: true,
  pagination: true
}

export const autoplaySettings: SwiperProps = {
  loop: true,
  autoplay: {
    delay: 0,
    disableOnInteraction: false
  },
  className: 'swiper-autoplay',
  speed: 7000,
  pagination: false,
  navigation: false,
  slidesPerView: 3,
  breakpoints: {
    [parseInt(breakPoints['sm'])]: {
      slidesPerView: 4
    }
  }
}

export const autoplayReverse: SwiperProps = {
  ...autoplaySettings,
  autoplay:
    typeof autoplaySettings.autoplay === 'object'
      ? { ...autoplaySettings.autoplay, reverseDirection: true }
      : { reverseDirection: true }
}

export const threeColSetting: SwiperProps = {
  breakpoints: {
    [parseInt(breakPoints['md'])]: {
      slidesPerView: 2,
      slidesPerGroup: 2
    },
    [parseInt(breakPoints['lg'])]: {
      slidesPerView: 3,
      slidesPerGroup: 3
    }
  }
}

export const Slider: FC<Props> = ({ settings, children, className }) => {
  const sliderSettings: SwiperProps = {
    ...defaultSettings,
    ...settings
  }

  if (!children) {
    return <div></div>
  }

  const nrOfSlides = children.length

  return (
    <div>
      <Swiper
        {...sliderSettings}
        className={classNames(sliderSettings.className, className)}
        pagination={
          sliderSettings.pagination
            ? {
              clickable: true,
              renderBullet: function (_: any, className: string) {
                return '<span class="' + className + '"></span>'
              }
            }
            : false
        }
        navigation={sliderSettings.navigation}
      >
        {children.map((child: any, i: number) => (
          <SwiperSlide key={i}>
            <div
              className={classNames(
                sliderSettings.pagination &&
                  nrOfSlides > 1 &&
                  'optionalNoPad pb-12 md:pb-14',
                nrOfSlides < 4 && 'threeCard',
                'h-full '
              )}
            >
              {child}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}
