import { FC } from 'react'
import classNames from 'classnames'

export const AvatarsComponent: FC<{
  avatars: {
    text: string
    image1: string
    image2: string
    image3: string
  }
}> = ({ avatars }) => {
  return (
    <div className="flex flex-col gap-2 sm:gap-0 sm:flex-row items-start sm:items-center">
      <div className="flex items-center">
        <AvatarIcon src={avatars.image1} />
        <AvatarIcon src={avatars.image2} className="relative -left-2" />
        <AvatarIcon src={avatars.image3} className="relative -left-4" />
      </div>

      <p className="text-white text-sm leading-tight">{avatars.text}</p>
    </div>
  )
}

const AvatarIcon: FC<{ src: string; className?: string }> = ({
  src,
  className
}) => {
  return (
    <div
      className={classNames(
        'bg-white clipAvatar h-[51px] flex items-center justify-center',
        className
      )}
    >
      <div
        className="clipAvatar h-12 bg-cover bg-center"
        style={{ backgroundImage: `url(${src})` }}
      ></div>
    </div>
  )
}
