import getYoutubeVideoIdFromURL from './get_youtube_video_id_from_url'

function getYoutubeThumbnailFromURL(url: string) {
  if (!url) return null

  const videoId = getYoutubeVideoIdFromURL(url)

  if (!videoId) return null

  return `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`
}

export default getYoutubeThumbnailFromURL
