import React, { FC, useState } from 'react'
import Head from 'next/head'
import { Header, Footer } from './'
import { PageConfig } from '../types/page_component_types'
import { CookieBanner } from './page_components'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { Locale } from '../types/shared_types'
import { isExternalLink } from '../utils/isExternalLink'

interface MetaData {
  title?: string
  description?: string
  image?: string
  ogImage?: string
  canonical_url?: string
}

export const Layout: FC<{
  meta?: MetaData
  children?: React.ReactNode
  slug?: string
  config: PageConfig
}> = ({ meta, children, slug, config }) => {
  const router = useRouter()

  const locale = (router.locale || 'en') as Locale

  const [open, setOpen] = useState(false)
  const { header, footer, metaData: defaultMeta } = config

  const defaultUrl = (process.env.NEXT_PUBLIC_HOSTNAME || '') + router.asPath

  const hrefLangTags = [
    <link
      key={'default'}
      rel={'alternate'}
      hrefLang={'x-default'}
      href={defaultUrl}
    />
  ]

  if (router.locales) {
    const alternateUrls = router.locales.map(localePathPrefix => {
      const alternateUrl = `${process.env.NEXT_PUBLIC_HOSTNAME || ''}${
        localePathPrefix === router.defaultLocale ? '' : '/' + localePathPrefix
      }${router.asPath}`

      return (
        <link
          key={localePathPrefix}
          rel={'alternate'}
          hrefLang={localePathPrefix}
          href={alternateUrl}
        />
      )
    })

    hrefLangTags.push(...alternateUrls)
  }

  const localePrefix =
    router.locale === router.defaultLocale ? '' : `/${router.locale}`
  const url = slug
    ? `${process.env.NEXT_PUBLIC_HOSTNAME}${localePrefix}${slug}`
    : process.env.NEXT_PUBLIC_HOSTNAME

  let image: string = ''

  if (meta?.ogImage) {
    image = meta.ogImage
  } else if (meta?.image) {
    image = meta.image
  } else if (defaultMeta?.image) {
    image = defaultMeta?.image
  }

  // if path relative, add hostname
  if (image.startsWith('/')) {
    image = `${process.env.NEXT_PUBLIC_MEDIA_HOST}${image.replace(
      '/media',
      ''
    )}`
  }

  let canonicalUrl: string | undefined

  if (meta?.canonical_url) {
    if (isExternalLink(meta.canonical_url)) {
      canonicalUrl = meta.canonical_url
    } else {
      canonicalUrl = `${process.env.NEXT_PUBLIC_HOSTNAME}${meta.canonical_url}`
    }
  }

  return (
    <div
      className={classNames(
        'w-full',
        open && 'fixed overflow-auto md:relative'
      )}
    >
      <Head>
        {/* General */}
        <title>{meta?.title || defaultMeta?.title}</title>
        <meta
          name="description"
          content={meta?.description || defaultMeta?.description}
        />
        {hrefLangTags}

        {/* Open Graph */}
        <meta
          property="og:description"
          content={meta?.description || defaultMeta?.description}
        />
        <meta property="og:title" content={meta?.title || defaultMeta?.title} />
        <meta property="og:url" content={url} />
        {image && <meta property="og:image" content={image} />}

        {/* Twitter */}
        {image && (
          <>
            <meta name="twitter:card" content="summary_large_image" />
            <meta property="twitter:image" content={image} />
          </>
        )}
        {!image && <meta name="twitter:card" content="summary" />}

        <meta name="twitter:site" content="@n3xtcoder" />
        <meta name="twitter:creator" content="@n3xtcoder" />
        <meta
          name="twitter:title"
          content={meta?.title || defaultMeta?.title}
        />
        <meta
          name="twitter:description"
          content={meta?.description || defaultMeta?.description}
        />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />

        {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}

        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
      </Head>
      <CookieBanner
        lang={locale}
        policyId={
          (locale === 'en'
            ? process.env.NEXT_PUBLIC_IUBENDA_POLICYID_EN
            : process.env.NEXT_PUBLIC_IUBENDA_POLICYID_DE) || ''
        }
      />
      <Header items={header || null} open={open} setOpen={setOpen} />
      <main>{children}</main>
      <Footer items={footer || null} />
    </div>
  )
}
