import React, { FC } from 'react'
import { ButtonTertiary } from './Buttons'
import Image from 'next/legacy/image'

export interface MemberCardProps {
  membername: string
  memberprofession: string
  memberinfo: string
  memberquote: string
  src: string
  alt: string
}

export const MemberCard: FC<
  Pick<
    Required<MemberCardProps>,
    | 'membername'
    | 'memberprofession'
    | 'memberinfo'
    | 'memberquote'
    | 'alt'
    | 'src'
  >
> = ({ membername, memberprofession, memberinfo, src, alt }) => {
  return (
    <article className="flex w-[360px] flex-col overflow-hidden p-6 md:h-[40rem]">
      <div className="flex flex-col items-center">
        {src && (
          <Image
            className="clipImage"
            src={src}
            alt={alt}
            width={168}
            height={188}
            objectFit="cover"
            layout="fixed"
          />
        )}
        <strong className="mt-6 mb-2">{membername}</strong>
        <p className="text-yellow70">{memberprofession}</p>
      </div>
      <p className="my-6 ">{memberinfo}</p>
    </article>
  )
}
