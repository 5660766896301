import { forwardRef, ReactNode } from 'react'
import classNames from 'classnames'

export const Section = forwardRef<
  HTMLElement,
  {
    fullWidth?: boolean
    className?: string
    sectionClassName?: string
    darkBackground?: boolean
    textFirst?: boolean
    type?: string
    children?: ReactNode
  }
>(
  (
    {
      children,
      fullWidth,
      darkBackground,
      className,
      textFirst,
      type,
      sectionClassName
    },
    ref
  ) => {
    const isWideImgText = type === 'imageTextBanner' && fullWidth
    const isEventBanner = type === 'eventBanner'

    return (
      <section
        ref={ref}
        className={classNames(
          'py-5 md:py-10',
          isEventBanner && 'mb-8 md:mb-14',
          darkBackground
            ? 'dark-background mb-5 bg-blue100 text-white md:mb-10'
            : 'text-blue100',
          sectionClassName
        )}
      >
        <div
          className={classNames(
            'mx-auto',
            type !== 'testimonials' && 'pr-6 pl-6 lg:pr-0 lg:pl-0',
            fullWidth ? 'max-w-[90rem]' : 'max-w-[70.5rem]',
            isWideImgText && textFirst && 'md:pr-0 md:pl-[5rem] lg:pl-[10rem]',
            isWideImgText && !textFirst && 'md:pr-[5rem] md:pl-0 lg:pr-[10rem]',
            className
          )}
        >
          {children}
        </div>
      </section>
    )
  }
)
Section.displayName = 'Section'
