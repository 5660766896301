import classNames from 'classnames'
import { FC } from 'react'
import ReactMarkdown from 'react-markdown'
import { BlockComponentContent } from '../../types/page_component_types'
import { Section } from '../Section'

/* https://www.figma.com/file/dlNJahOgzHG8DrFEL3srnt/Design-System?node-id=1099%3A10964 */
export const TonnenheldBlockComponent: FC<{
  content: BlockComponentContent
}> = ({ content }) => (
  <Section type={content.type}>
    <ReactMarkdown
      className={classNames(
        'contentStyles max-w-full md:w-[46.5rem]',
        content.textCentered && 'mx-auto text-center'
      )}
    >
      {content.block || ''}
    </ReactMarkdown>
  </Section>
)
