import Image from 'next/legacy/image'
import React, { FC } from 'react'
import { VenturesComponentContent } from '../../types/page_component_types'
import { Section } from '../Section'
import { autoplayReverse, autoplaySettings, Slider } from '../Slider'
import { LogoCarousselCardProps } from './ClientsComponent'

/* https://www.figma.com/file/dlNJahOgzHG8DrFEL3srnt/Design-System?node-id=274%3A4214 */
export const VenturesComponent: FC<{ content: VenturesComponentContent }> = ({
  content
}) => {
  const ventures = content.ventures
  if (!ventures) return null
  if (ventures.length < 13) {
    return (
      <Section fullWidth>
        <div className="mx-auto flex max-w-[1100px] flex-wrap justify-center gap-4 md:gap-6 ">
          {ventures?.map((card, i) => (
            <React.Fragment key={i}>
              {card?.src && (
                <LogoCarousselCard
                  src={card?.src || ''}
                  alt={card?.alt || ''}
                />
              )}
            </React.Fragment>
          ))}
        </div>
      </Section>
    )
  }

  if (ventures.length < 33) {
    return (
      <Section fullWidth>
        <div className="threeCard fiveCard">
          <Slider settings={autoplaySettings}>
            {ventures?.map((card, i) => {
              return (
                <div className="px-2 md:px-3" key={i}>
                  {card?.src && (
                    <LogoCarousselCard
                      src={card?.src || ''}
                      alt={card?.alt || ''}
                    />
                  )}
                </div>
              )
            })}
          </Slider>
        </div>
      </Section>
    )
  }

  const firstLineLogoCount = Math.floor(ventures.length / 2)
  return (
    <Section fullWidth className="firstLogoSlider">
      <div className="threeCard fiveCard">
        <Slider settings={autoplaySettings}>
          {ventures?.slice(0, firstLineLogoCount).map((card, i) => {
            return (
              <div className=" px-2 py-2 md:py-6 md:px-3" key={i}>
                {card?.src && (
                  <LogoCarousselCard
                    src={card?.src || ''}
                    alt={card?.alt || ''}
                  />
                )}
              </div>
            )
          })}
        </Slider>
      </div>
      <div className="threeCard fiveCard">
        <Slider settings={autoplayReverse}>
          {ventures?.slice(firstLineLogoCount).map((card, i) => {
            return (
              <div className="px-2 py-2 md:px-3 md:py-3" key={i}>
                {card?.src && (
                  <LogoCarousselCard
                    src={card?.src || ''}
                    alt={card?.src || ''}
                  />
                )}
              </div>
            )
          })}
        </Slider>
      </div>
    </Section>
  )
}

const LogoCarousselCard: FC<LogoCarousselCardProps> = ({ alt, src }) => {
  return (
    <div className="dropShadowBig">
      <div className="clipLogo relative h-[100px] w-[100px] bg-white py-4 px-2 md:h-[152px] md:w-[152px] md:py-9 md:px-4">
        <div className="relative h-full w-full">
          <Image src={src} alt={alt || ''} layout="fill" objectFit="contain" />
        </div>
      </div>
    </div>
  )
}
