import classNames from 'classnames'
import Image from 'next/legacy/image'
import Link from 'next/link'
import { FC } from 'react'
import { useTranslations } from 'next-intl'

import { Image as ImageProp } from '../types/page_component_types'
import { ButtonContent, ButtonTertiary } from './Buttons'

export interface ServiceCardProps {
  heading: string
  image: ImageProp
  description: string
  slug: string
  readMoreButton: boolean
  isCollectionCard?: boolean
}

export const ServiceCard: FC<ServiceCardProps> = ({
  heading,
  description,
  slug,
  image,
  readMoreButton,
  isCollectionCard
}) => {
  const t = useTranslations()

  return (
    <Link
      href={slug || ''}
      className={classNames(!readMoreButton && 'pointer-events-none')}
    >
      <article
        className={classNames(
          'dropShadowSmall group flex h-full w-full flex-col bg-blue10',
          !isCollectionCard && 'py-6 px-5 md:px-4 md:py-8',
          isCollectionCard && 'px-2 py-4 md:px-4 md:py-8'
        )}
      >
        <div
          className={classNames(
            isCollectionCard &&
              'mb-4 flex h-full flex-col-reverse last:mb-0 sm:mb-0 sm:flex sm:h-auto sm:flex-col'
          )}
        >
          <h4
            className={classNames(
              'h-[3.875rem] group-hover:text-turquoise70',
              !isCollectionCard && 'mx-auto select-text text-center',
              isCollectionCard &&
                'mt-auto text-left sm:mx-auto sm:select-text sm:text-center'
            )}
          >
            {heading}
          </h4>

          <figure
            className={classNames(
              !isCollectionCard &&
                'relative mx-auto my-6 h-[6.25rem] w-[6.25rem] md:mb-8',
              isCollectionCard &&
                'relative h-14 w-14 sm:mx-auto sm:my-6 sm:h-[6.25rem] sm:w-[6.25rem] md:mb-8'
            )}
          >
            {image && image.src && (
              <Image
                src={image.src}
                alt={image?.alt || ''}
                layout="fill"
                objectFit="contain"
              />
            )}
          </figure>

          <p
            className={classNames(
              'mb-11 select-text text-base font-light md:h-56',
              isCollectionCard && 'hidden sm:block'
            )}
          >
            {description || ''}
          </p>
        </div>
        {slug && readMoreButton && <ButtonContent label={t('read_more')} />}
      </article>
    </Link>
  )
}
