import classNames from 'classnames'
import React, { FC } from 'react'
import { HTMLComponentContent } from '../../types/page_component_types'
import { Section } from '../Section'

export const HTMLComponent: FC<{ content: HTMLComponentContent }> = ({
  content
}) => (
  <Section type={content.type}>
    <p
      className="contentStyles"
      dangerouslySetInnerHTML={{ __html: content?.block || '' }}
    ></p>
  </Section>
)
